import {
  BackendResponse,
  DeploymentMonitorApplication,
  DeploymentStatusEntry,
  Version,
  YouTrackTask,
  InstanceGitDiffPair,
  DeploymentPreReleaseInfo
} from '../models/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { backendDateToDate } from './utils';

@Injectable({ providedIn: 'root' })
export class DeploymentMonitorBackendService {

  constructor(private http: HttpClient) { }

  fechApplications(): Observable<DeploymentMonitorApplication[]> {
    return this.http.get<BackendResponse<DeploymentMonitorApplication[]>>(`${environment.coreServerUrl}/deploymentmonitor/applications`, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<DeploymentMonitorApplication[]>) => response.Result),
      map((entries: DeploymentMonitorApplication[]) => entries.map(entry => {
        entry.Timestamp = backendDateToDate(entry.Timestamp).format('MMMM Do YYYY, h:mm:ss a');
        return entry;
      }))
    );
  }
  fetchReleaseNotes(env: string, application: string, instance: string): Observable<string> {
    return this.http.get(`${environment.coreServerUrl}/deploymentmonitor/applications/${env}/${application}/${instance}/releasenotes`, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  fetchVersions(): Observable<Version[]> {
    return this.http.get<BackendResponse<Version[]>>(`${environment.coreServerUrl}/deploymentmonitor/versions/AdServerNet?take=1000`, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<Version[]>) => response.Result),
      map((entries: Version[]) => entries.map(entry => {
        entry.Timestamp = backendDateToDate(entry.Timestamp).format('YYYY/MM/DD hh:mm:ss');
        return entry;
      }))
    );
  }

  fetchAdServerDeployment(env: string): Observable<DeploymentStatusEntry[]> {
    return this.http.get<BackendResponse<any>>(
      `${environment.backendUrl}/watchdog/v1/deployment/services?environment=${env}`, {
        withCredentials: true
      }).pipe(
        map((response: BackendResponse<any>) => response.Result),
        map(entry => {
          const output = [];
          for (const item in entry) {
            if (entry.hasOwnProperty(item)) {
              output.push(<DeploymentStatusEntry>{ Name: item, Version: entry[item] });
            }
          }

          return output;
        })
      );
  }

  fetchChanges(env: string, versions: any): Observable<DeploymentPreReleaseInfo> {
    return this.http.post<BackendResponse<any>>(
      `${environment.coreServerUrl}/deploymentmonitor/applications/${env}/AdServerNet/prereleaseinfo`, versions, {
        withCredentials: true
      }).pipe(
        map((response: BackendResponse<any>) => response.Result),
        map(result => {
          const output = [];
          for (const item in result.Diffs) {
            if (result.Diffs.hasOwnProperty(item)) {
              output.push(<InstanceGitDiffPair>{ Instance: item, Diff: result.Diffs[item] });
            }
          }

          return <DeploymentPreReleaseInfo>{ Deployed: result.Deployed, Undeployed: result.Undeployed, Diffs: output }
        })
      );
  }

  deploy(env: string, versions: any): Observable<string> {
    return this.http.post(`${environment.backendUrl}/watchdog/v1/deployment/deploy`, { Environment: env, Services: versions }, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  runE2ETests(): Observable<string> {
    return this.http.post(`${environment.backendUrl}/watchdog/v1/deployment/run/e2etests`, {}, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}
