import { BackendResponse } from '../models/constants';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import {
  WEWORK_COMPANIES_URL,
  WEWORK_MAPPINGS_URL,
  WEWORK_SEND_INVOICE_URL,
  WeWorkCompany,
  WeWorkDisplayGroup,
  WeWorkInvoiceRequest
  } from '../models/wework';

@Injectable({
  providedIn: 'root'
})
export class WeWorkService {

  displayGroups: WeWorkDisplayGroup[] = [];
  unmappedDisplayGroups: WeWorkDisplayGroup[] = [];
  mappedDisplayGroups: WeWorkDisplayGroup[] = [];
  companies: WeWorkCompany[] = [];

  displayGroupInEdit: WeWorkDisplayGroup;

  constructor(private http: HttpClient) { }

  fetchWeWorkDisplayGroups(): Promise<WeWorkDisplayGroup[]> {
    return this.http.get<BackendResponse<WeWorkDisplayGroup[]>>(`${environment.coreServerUrl}/${WEWORK_MAPPINGS_URL}`, {
      withCredentials: true
    }).pipe(
      map(response => response.Result),
      tap(groups => this.setDisplayGroups(groups)))
    .toPromise();
  }

  fetchWeWorkCompanies(): Promise<WeWorkCompany[]> {
    return this.http.get<BackendResponse<WeWorkCompany[]>>(`${environment.coreServerUrl}/${WEWORK_COMPANIES_URL}`, {
      withCredentials: true
    }).pipe(
      map(response => response.Result),
      tap(companies => this.setCompanies(companies)))
    .toPromise();
  }

  async saveMapping(displayGroup: WeWorkDisplayGroup, companyId: string): Promise<BackendResponse<void>> {
    const result = await this.http.post<BackendResponse<void>>(`${environment.coreServerUrl}/${WEWORK_MAPPINGS_URL}`, {
      DisplayGroupMappings: [{
          DisplayGroupId: displayGroup.DisplayGroupId,
          CompanyId: companyId
        }
      ]
    }, {
      withCredentials: true
    }).toPromise();
    this.updateDisplayGroup(displayGroup, companyId);
    this.displayGroups = [...this.displayGroups];
    this.mappedDisplayGroups = [...this.mappedDisplayGroups];
    this.unmappedDisplayGroups = [...this.unmappedDisplayGroups];
    return result;
  }

  prepareInvoice(companiesToBill: string[]): WeWorkInvoiceRequest {
    if (this.mappedDisplayGroups && this.mappedDisplayGroups.length && companiesToBill.length) {
      const invoiceRequestData: WeWorkInvoiceRequest = {
        Invoices: [],
        InvoiceDate: new Date()
      };
      this.mappedDisplayGroups.forEach(group => {
        if (companiesToBill.includes(group.CompanyId)) {
          let companyBill = invoiceRequestData.Invoices.find(invoice => invoice.CompanyId === group.CompanyId);
          if (!companyBill) {
            companyBill = { CompanyId: group.CompanyId, DeviceCount: 0 };
            invoiceRequestData.Invoices.push(companyBill);
          }
          companyBill.DeviceCount += group.DeviceCount;
        }
      });
      return invoiceRequestData;
    }
  };

  async sendInvoiceRequest(invoice: WeWorkInvoiceRequest): Promise<BackendResponse<any>> {
    const invoiceCopy = Object.assign({}, invoice);
    if (invoice.InvoiceDate instanceof Date) {
      invoiceCopy.InvoiceDate = invoice.InvoiceDate.toISOString();
    }
    return this.http.post<BackendResponse<any>>(`${environment.coreServerUrl}/${WEWORK_SEND_INVOICE_URL}`, invoiceCopy, {
      withCredentials: true
    }).toPromise();
  }

  updateDisplayGroup(displayGroupData: WeWorkDisplayGroup, companyId: string): void {
    const unmappedIndex = this.unmappedDisplayGroups.findIndex(dp => dp.DisplayGroupId === displayGroupData.DisplayGroupId);
    if (unmappedIndex > -1) {
      const unmappedGroup = this.unmappedDisplayGroups[unmappedIndex];
      unmappedGroup.CompanyId = companyId;
      unmappedGroup.DeviceCount = displayGroupData.DeviceCount;
      this.mappedDisplayGroups.push(unmappedGroup);
      this.unmappedDisplayGroups.splice(unmappedIndex, 1);
      return;
    }
    const mappedIndex = this.mappedDisplayGroups.findIndex(dp => dp.DisplayGroupId === displayGroupData.DisplayGroupId);
    if (mappedIndex > -1) {
      const mappedGroup = this.mappedDisplayGroups[mappedIndex];
      mappedGroup.CompanyId = companyId;
      mappedGroup.DeviceCount = displayGroupData.DeviceCount;
      return;
    }
  }

  private setDisplayGroups(displayGroups: WeWorkDisplayGroup[]) {
    this.mappedDisplayGroups = [];
    this.unmappedDisplayGroups = [];
    displayGroups.forEach(group => {
      if (typeof group.CompanyId === 'string') {
        this.mappedDisplayGroups.push(group);
      } else {
        this.unmappedDisplayGroups.push(group);
      }
    });
    this.displayGroups = displayGroups;
  }

  private setCompanies(companies: WeWorkCompany[]) {
    this.companies = companies;
  }
}
