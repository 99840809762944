import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WeWorkService } from '../services/wework.service';
import { WeWorkCompany } from '../models/wework';

@Injectable({
  providedIn: 'root'
})
export class WeWorkCompaniesResolver implements Resolve<WeWorkCompany[]> {
  constructor(private wework: WeWorkService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.wework.fetchWeWorkCompanies();
  }
}