import { AdserverLogsComponent } from './adserver-logs/adserver-logs.component';
import { AdserverAuditsComponent } from './adserver-audits/adserver-audits.component';
import { AdserverChangelogComponent } from './adserver-changelog/adserver-changelog.component';
import { AdserverCommandsComponent } from './adserver-commands/adserver-commands.component';
import { DeploymentsComponent } from './deployment/deployment.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlatformsResolver } from './resolvers/platforms.resolver';
import { AdserverDeploymentComponent } from './adserver-deployment/adserver-deployment.component';
import { AdserverResourceMonitoringComponent } from './adserver-resource-monitoring/adserver-resource-monitoring.component';
import { WeWorkInvoiceComponent } from './wework/wework-invoice/wework-invoice.component';
import { WeWorkDisplayGroupsResolver } from './resolvers/wework-displaygroups.resolver';
import { WeWorkCompaniesResolver } from './resolvers/wework-companies.resolver';
import { WeWorkGuard } from './resolvers/wework.guard';
import { FeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { FlagOverridesComponent } from './feature-flags/flag-overrides/flag-overrides.component';
import { FeatureFlagOverridesResolver } from './resolvers/feature-flag-overrides.resolver';


const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/deployment',
    pathMatch: 'full'
  },
  {
    path: 'deployment',
    component: DeploymentsComponent,
  },
  {
    path: 'changelog',
    component: AdserverChangelogComponent,
  },
  {
    path: 'logs',
    component: AdserverLogsComponent,
  },
  {
    path: 'audits',
    component: AdserverAuditsComponent,
  },
  {
    path: 'adserver-deployment',
    component: AdserverDeploymentComponent,
  },
  {
    path: 'wework',
    component: WeWorkInvoiceComponent,
    canActivate: [WeWorkGuard],
    resolve: {
      displaygroups: WeWorkDisplayGroupsResolver,
      companies: WeWorkCompaniesResolver,
    }
  },
  {
    path: 'commands',
    component: AdserverCommandsComponent,
  },
  {
    path: 'resource-monitoring',
    component: AdserverResourceMonitoringComponent,
  },
  {
    path: 'feature-flags',
    component: FeatureFlagsComponent,
  },
  {
    path: 'feature-flags/:flagName',
    component: FlagOverridesComponent,
    resolve: {
      overrides: FeatureFlagOverridesResolver
    }
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
