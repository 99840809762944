import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { FeatureFlagDescription, FlagDataType } from '../models/constants';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationService } from 'primeng/api';

const DEFAULT_FLAG = {
  Name: '',
  IsEnterprise: false,
  CanBeSetByNetworkAdmin: false,
  DataType: 'int' as FlagDataType,
  DefaultValue: '0'
};

@Component({
  selector: 'ep-feature-flags',
  templateUrl: './feature-flags.component.html',
  styleUrls: ['./feature-flags.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeatureFlagsComponent implements OnInit {

  datasource: MatTableDataSource<FeatureFlagDescription>;
  columnsToDisplay = ['Name', 'IsEnterprise', 'CanBeSetByNetworkAdmin', 'DataType', 'DefaultValue', 'delete', 'overrides'];
  defaultFlag = DEFAULT_FLAG;

  constructor(private flagsService: FeatureFlagsService,
              private confirmation: ConfirmationService) {
              }

  async ngOnInit() {
    await this.fetchFeatureFlags();
  }

  get flagInEdit() {
    if (this.flagsService.flagInEdit) {
      return this.flagsService.flagInEdit;
    }
    return null;
  }

  get editing(): boolean {
    return this.flagsService.editing;
  }

  set flagInEdit(flag: FeatureFlagDescription) {
    this.flagsService.flagInEdit = flag;
  }

  setFeatureFlagData(flags: FeatureFlagDescription[]) {
    if (!this.datasource) {
      this.datasource = new MatTableDataSource<FeatureFlagDescription>();
    }
    this.datasource.data = flags;
  }

  getDialogTitle(): string {
    if (this.flagsService.flagInEdit) {
      return `Editing Flag ${this.flagsService.flagInEdit.Name}`;
    } else {
      return `New Flag`;
    }
  }

  openDialog(flag?: FeatureFlagDescription) {
    this.flagsService.flagInEdit = flag ?
      {... flag} :
      {... this.defaultFlag};
    this.flagsService.editing = flag != null;
  }

  closeDialog() {
    this.flagsService.flagInEdit = null;
    this.flagsService.editing = false;
  }

  async saveFlag(flag: FeatureFlagDescription) {
    await this.flagsService.postFeatureFlag(flag);
    await this.fetchFeatureFlags();
    this.closeDialog();
  }

  async deleteFlag(flag: FeatureFlagDescription, event: Event) {
    event.stopPropagation();
    this.confirmation.confirm({
      message: `Are you sure you want to delete ${flag.Name} flag?`,
      accept: async () => {
        await this.flagsService.deleteFeatureFlag(flag.Name);
        await this.fetchFeatureFlags();
      }
    });
  }

  private async fetchFeatureFlags() {
    const flags = await this.flagsService.fetchFeatureFlags();
    this.setFeatureFlagData(flags);
  }
}
