import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { WeWorkDisplayGroup, WeWorkCompany } from '../../models/wework';
import { WeWorkService } from '../../services/wework.service';

@Component({
  selector: 'ep-wework-location-dialog',
  templateUrl: './wework-location-dialog.component.html',
  styleUrls: ['./wework-location-dialog.component.scss']
})
export class WeworkLocationDialogComponent implements OnInit {

  @Input() dialogClosed$: EventEmitter<void>;
  @Input() dialogOpened$: EventEmitter<void>
  company: WeWorkCompany;

  get displayGroupInEdit(): WeWorkDisplayGroup {
    return this.wework.displayGroupInEdit;
  }

  set displayGroupInEdit(group: WeWorkDisplayGroup) {
    this.wework.displayGroupInEdit = Object.assign({}, group);
    if (this.displayGroupInEdit.CompanyId) {
      this.company = this.companies.find(comp => comp.CompanyReferenceId === this.displayGroupInEdit.CompanyId);
    } else {
      this.company = null;
    }
  }

  get displayGroups(): WeWorkDisplayGroup[] {
    return this.wework.displayGroups.sort((a, b) => {
      if (!this.isDisplayGroupMapped(a) && this.isDisplayGroupMapped(b)) {
        return -1;
      } else if (this.isDisplayGroupMapped(a) && !this.isDisplayGroupMapped(b)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  get companies(): WeWorkCompany[] {
    return this.wework.companies;
  }

  constructor(private wework: WeWorkService) { }

  ngOnInit() {
    this.dialogOpened$.subscribe(_ => {
      if (this.displayGroupInEdit && this.isDisplayGroupMapped(this.displayGroupInEdit)) {
        this.company = this.companies.find(comp => comp.CompanyReferenceId === this.displayGroupInEdit.CompanyId);
      } else {
        this.company = null;
      }
    });
    if (this.dialogClosed$) {
      this.dialogClosed$.subscribe(_ => {
        this.resetDialog();
      });
    }
  }

  getCompanyNameForDisplayGroup(group: WeWorkDisplayGroup): string {
    if (!this.isDisplayGroupMapped(group)) {
      return 'No Company Assigned to this Display Group!';
    } else {
      const assignedCompany = this.wework.companies.find((company) => company.CompanyReferenceId === group.CompanyId);
      return `${assignedCompany.Name} (${assignedCompany.Organization})`;
    }
  }

  isDisplayGroupMapped(group: WeWorkDisplayGroup): boolean {
    return typeof group.CompanyId === 'string';
  }

  companyChange() {
    if (this.displayGroupInEdit)  {
      this.displayGroupInEdit.CompanyId = this.company ? this.company.CompanyReferenceId : null;
    }
  }

  unitCountChange(event: Event) {
    const count = Number((<HTMLInputElement>event.target).value);
    if (count && this.displayGroupInEdit) {
      this.displayGroupInEdit.DeviceCount = count;
    }
  }

  private resetDialog() {
    this.company = null;
  }
}
