<div class="card">
  <div class="platform-selector">
    <h2 class="row">Generate changelog</h2>
    <div class="platform-newapp-container">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Env:</span>
        <p-dropdown [options]="environments" [ngModel]="selectedEnvironment"
          (onChange)="environmentChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>

      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Application:</span>
        <p-dropdown [options]="environmentApplications" [ngModel]="selectedEnvironmentApplication"
          (onChange)="environmentApplicationChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>


      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Instance:</span>
        <p-dropdown [options]="environmentApplicationInstances" [ngModel]="selectedEnvironmentApplicationInstance"
          (onChange)="environmentApplicationInstanceChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>

      <button class="button load-changelog" [attr.disabled]="buttonDisabled ? true : null"
        (click)="fetchReleaseNotes()">Get</button>
    </div>
  </div>

  <pre style="white-space: pre-wrap; word-wrap: break-word">{{releaseNotes}}</pre>
</div>