<div class="card">
  <h1 class="header"><i class="icon__header icon ion-ios-arrow-back" routerLink="/feature-flags"></i> Flag {{ flagName }} overrides</h1>
  
  <table mat-table [dataSource]="dataSource">
    
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let overrides; columns: columnsToDisplay" class="table-row"></tr>
  
    <ng-container matColumnDef="NetworkName">
      <th mat-header-cell *matHeaderCellDef> Network Name </th>
      <td mat-cell *matCellDef="let override"
          class="table-item-name"> {{ override.NetworkName }}</td>
    </ng-container>

    <ng-container matColumnDef="AccountName">
      <th mat-header-cell *matHeaderCellDef> Account Name </th>
      <td mat-cell *matCellDef="let override"
          class="table-item-name"> {{ override.AccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="Value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let override"
          class="table-item-name">
        <ep-boolean-icon *ngIf="isValueBoolean(override)" [value]="override.Value"></ep-boolean-icon>
        <span class="default-value" *ngIf="!isValueBoolean(override)"> {{ override.Value }}</span>
      </td>
    </ng-container>
  </table>
</div>