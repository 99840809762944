<div class="deployment-dialog">
  <section *ngIf="errors?.length > 0">
    <div class="error" *ngFor="let error of errors">
      {{ error }}
    </div>
  </section>
  <section>
    <h5>Select deployment level</h5>
    <div class="deployment-options-container">
      <p-dropdown
        [options]="levelSelectOptions" 
        [ngModel]="targetLevel" 
        (onChange)="onLevelSelected($event.value)">
      </p-dropdown>
      <div class="excluded-networks__container" *ngIf="targetLevel === 'Enplug' && levelOptions && levelOptions.length > 0">
        <span class="excluded-networks__copy">Excluded Networks</span>
        <p-multiSelect
        class="selected-level"
        itemSize="30"
        [options]="levelOptions"
        [virtualScroll]="false"
        [filter]="true"
        [style]="{ 'width': '80%' }"
        (onChange)="onExcludedNetworksSelected($event.value)"
        [showToggleAll]="false">
        </p-multiSelect>
      </div>
      <p-dropdown *ngIf="targetLevel !== 'Enplug' && levelOptions && levelOptions.length > 0"
        class="selected-level" 
        itemSize="30"
        [options]="levelOptions"
        [appendTo]="'body'"
        [virtualScroll]="true"
        [placeholder]="'Select '+ targetLevel"
        [filter]="true"
        [style]="{ 'width': '80%' }"
        (onChange)="onLevelIdSelected($event.value)">
        <ng-template let-item pTemplate="selectedItem">
          <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
            <span>{{ item.value.Name }}</span>&nbsp;<span class="target-id">({{ item.value.LevelId }})</span>
          </span>
        </ng-template> 
        <ng-template let-item pTemplate="item"> 
          <div style="display:flex; flex-direction: column;">
            <span>{{ item.value.Name }}</span>
            <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="target-id">{{ item.value.LevelId }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <i class="pi pi-spin pi-spinner" 
      style="position:relative; left: 65%; font-size: 3em" 
      *ngIf="targetLevel != 'Enplug' && levelOptions == null"></i>
  </section>
  <section>
    <div class="columns">
      <div class="source">
        <h5>Select Source Options</h5>
        <div class="dropdown-wrapper">
          <p-dropdown
          [options]="sourceSelectOptions"
          [ngModel]="source"
          [appendTo]="'body'"
          (onChange)="onSourceChanged($event.value)">
          </p-dropdown>
        </div>
      </div>
      <div class="app">
      <h5>Select App to Deploy</h5>
      <div class="dropdown-wrapper">
        <p-dropdown
          optionLabel="AppName"
          [options]="deployments"
          [ngModel]="app"
          [appendTo]="'body'"
          [style]="{ 'width': '80%' }"
          (onChange)="onAppSelected($event.value)">
        </p-dropdown>
      </div>
      </div>
    </div>
  </section>
  <section *ngIf="source === 'build' || source === 'resource'">
    <h5>Build Versions</h5>
    <div class="columns">
      <div>
        <div>{{ source === 'build' ? 'Build' : 'Resource' }} Version</div>
        <input type="text" [value]="app?.BuildVersion" (change)="onBuildVersionChanged($event.target.value)" />
      </div>
      <div>
        <div>App Version</div>
        <input type="text" [value]="app?.AppVersion" (change)="onAppVersionChanged($event.target.value)" />
      </div>
    </div>
  </section>
  <section *ngIf="source === 'build'">
    <h5>Select Deployment Time</h5>
    <div class="deployment-time-container">
      <p-radioButton name="deployment-time-options"
        value="now"
        label="Deploy Now"
        [(ngModel)]="deployWhen">
      </p-radioButton>
      <div class="column">
        <p-radioButton name="deployment-time-options"
          value="later"
          label="Deploy Later"
          [(ngModel)]="deployWhen">
        </p-radioButton> 
        <p-calendar *ngIf="deployWhen === 'later'"
          [(ngModel)]="deployDate"
          [timeOnly]="true"
          [appendTo]="'body'"
          (onClose)="onDeployTimeChanged()"></p-calendar>
      </div>
    </div>
  </section>
  <section *ngIf="source === 'resource'">
    <h5>Resource Upload</h5>
    <div class="file-type-text">File type {{ platform?.FileExtension }}</div>
    <label for="file-upload" class="button upload" [ngClass]="{ 'disabled' : uploadPending }">
      {{ getUploadButtonText() }}
    </label>
    <input 
      id="file-upload"
      type="file"
      [disabled]="uploadPending"
      [accept]="platform?.FileExtension"
      (change)="handleFileInput($event.target.files)" />
  </section>
</div>

