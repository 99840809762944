export const DEPLOYMENTS_URL = `/edu/app/deployments`;
export const ALL_DEPLOYMENTS_URL = `/edu/app/deployments/all`;
export const EDU_STATUSES_URL = `/edumonitoring/edustatuses/filter`;
export const NETWORKS_URL = `/networks`;
export const ACCOUNTS_URL = `/accounts`;
export const PLATFORMS_URL = `/edu/app/deployments/platforms`;
export const UPLOAD_URL = `/edu/app/upload`;
export const DEPLOY_FROM_RESOURCE_URL = `/edu/app/deploy/fromresource`;
export const DEPLOY_FROM_BUILD_URL = `/edu/app/deploy/frombuild`;
export const REFRESH_URL = `/authentication/refresh`;
export const FEATURE_FLAGS_URL = `/maintenance/featureflags`

export type DeploymentSource = 'build' | 'resource';
export type TargetLevel = 'Enplug' | 'Network' | 'Account' | 'Edu';

export interface Platform {
  FileExtension: string,
  Platform: string,
}

export interface BackendResponse<T> {
  Success: boolean,
  Result: T
}

export interface App {
  AppName: string,
  AppVersion: number
  BuildVersion: number
  Created: string,
  DeployTime: string,
  Id: string,
  JarUrl: string,
  LastSaved: string,
  PackageUrl: string,
  Platform: string,
  State: string,
  SubState: string,
  Url: string
  ZipUrl: string
}

export interface DeviceDetils {
  MacAddressEthernet: string,
  MacAddressWifi: string,
  IpAddress: string,
  Venue: VenueDataItem,
  Edu: Edu,
  Tags: string[],
  StatusCode: string,
  StatusMessage: string,
  LastPlayerHeartbeat: string,
  PlayerUptime: number,
  DeviceUptime: number,
  TvStatus: string,
  PlayerVersion: number,
  Apps: number,
  Created: string,
  FilteredValues: any[]
}

export interface Network {
  Id: string,
  Name: string,
  IsMaster: boolean,
  Created: string,
  HasAutomaticBilling: boolean,
  NetworkSettings: NetworkSettings,
  DisplayGroupCreateSettings: DisplayGroupCreateSettings
}

export interface NetworkSettings {
  Branding: Branding,
  Color: Color,
  Support: Support
}

export interface Branding {
  Name: string,
  LegalName: string,
  Website: string,
  AddressLine1: string,
  AddressLine2: string,
  Logo: string
}

export interface Color {
  NavigationBar: string,
  NavigationLinks: string,
  NavigationHover: string,
  PrimaryColor: string,
  SecondaryColor: string
}

export interface Support {
  Email: string,
  SupportUrl: string,
  NavigationHover: string,
  ContactUrl: string,
  VideoTutorial: string,
  OrderDevicesUrl: string,
  HelpMessage: string,
  IntercomMessaging: boolean,
  ShowHelpdeskContent: boolean,
  ReleaseLogUrl: string,
  LogoutRedirectUrl: string
}

export interface DisplayGroupCreateSettings {
  ShowAddress: boolean,
  ShowScreenType: boolean,
  ShowSleep: boolean,
  CreateWithNewAccounts: boolean
}

export interface AccountInfo {
  Id: string,
  Name: string,
  Created: string,
  State: string,
  AccountType: string,
  IsDeveloper: boolean,
  Venues: DataItemInfo[],
  AppPackageId: string
}

export interface DataItemInfo {
  Id: string,
  Name: string
}

export interface VenueDataItem {
  TimeZone: string,
  Id: string,
  Name: string
}

export interface Venue {
  Id: string,
  Name: string,
  ProfileImageHttpsPath: string,
  ProfileImageResourceId: string,
  Created: string,
  AccountId: string,
  NetworkId: string,
  RegionId: string,
  IsLandscape: boolean,
  Language: string,
  DotNetTimeZone: string,
  TimeZone: string,
  Description: string,
  PhotoUrl: string,
  PhoneNumber: string,
  Address: Address,
  ScreenType: string,
  IpAddress: string,
  Tags: string[],
  Geolocation: Geolocation,
  HolidayHours: HolidayHours[],
  OpeningHours: OpeningHours[]
}

export interface Address {
  BuildingName: string,
  Street: string,
  City: string,
  State: string,
  ZipCode: string,
  Country: string
}

export interface Geolocation {
  Items: GeolocationItem[],
  CurrentMethod: string
}

export interface GeolocationItem {
  Created: string,
  Latitude: string,
  Longitude: string,
  Method: string
}

export interface HolidayHours {
  Date: string,
  OpeningHours: OpeningHours
}

export interface OpeningHours {
  Blocks: TimeBlock[]
}

export interface TimeBlock {
  From: string,
  To: string
}

export interface Edu {
  Id: string,
  Name: string
}

export interface DeployTarget {
  Level: string,
  LevelId: string,
  Name: String
}

export interface DeploymentMonitorApplication {
  Environment: string,
  Application: string,
  Instance: string,
  Version: string,
  Commit: string,
  Timestamp: string
}

export interface LogEntry {
  Timestamp: string,
  Properties: any,
  Level: string,
  RenderedMessage: string
}

export interface AuditEntry {
  Time: string,
  Payload: any,
  IsSuccess: boolean,
  DurationInMs: number,
  UserName: string,
  Container: string,
  Service: string,
  Method: string,
  Context: string,
  Exception: string
}

export interface DeploymentStatusEntry {
  Name: string,
  Version: string
}

export interface Version {
  Version: string,
  Commit: string,
  Timestamp: string,
}

export interface YouTrackTask {
  Type: string,
  Title: string,
  Subsystem: string,
  State: string,
  Services: string[],
  Link: string,
  Id: string,
  FixVersion: string,
  Description: string,
  Comments: YouTrackComment[]
}

export interface YouTrackComment {
  Author: string,
  Text: string,
  Timestamp: string,
  Replies: YouTrackComment[]
}

export interface Diff {
  Issues: YouTrackTask[],
  Commits: GitDiff
}

export interface GitDiff {
  From: string,
  To: string,
  IsAhead: boolean,
  Link: string,
  Commits: GitCommit[]
}

export interface GitCommit {
  Hash: string,
  Message: string,
  Author: string,
  Timestamp: string,
  Link: string
}

export interface InstanceGitDiffPair {
  Instance: string,
  Diff: GitDiff
}

export interface DeploymentPreReleaseInfo {
  Deployed: YouTrackTask[],
  Undeployed: YouTrackTask[],
  Diffs: InstanceGitDiffPair[]
}

export interface DeployRequestBase {
  AppName: string,
  Level: string,
  LevelId: string,
  Platform: string,
  ExcludeNetworkIds?: string[]
}

export interface DeployFromResourceRequest extends DeployRequestBase {
  AppVersion: number,
  FileKey: string
}

export interface DeployFromBuildRequest extends DeployRequestBase {
  AppVersion: number,
  BuildVersion: number,
  DeployTime: string,
}

export type DeployRequest = DeployFromBuildRequest | DeployFromResourceRequest;

export class ValidationResult {
  get valid() {
    return this.errors.length === 0;
  };

  errors: string[] = [];
};

export interface Command {
  Name: string,
  Description: string,
  Parameters: CommandParameter[]
}

export interface CommandParameter {
  Name: string,
  Type: string,
  DefaultValue: any
}

export interface MediaMonitoringInfo {
   MediaMonitoringInfo: ResourceDetails[],
   Pagination: Pagination
}

export interface Pagination {
  TotalCount: Number,
  HasPrev: boolean,
  HasNext: boolean,
  Prev: string,
  Next: string
}

export interface ResourceDetails {
  Id: string,
  Name: string,
  AccountId: string,
  AssetId: string,
  Created: string,
  UserId: string,
  Status: string,
  FileType: string,
  ErrorMessage: string,
  ProgressPct: Number,
  TimeSinceLastSaved: string,
  VideoNormalResolutionDuration: Number,
  VideoHighResolutionDuration: Number,
  TotalEncodingTime: Number
}

export type FlagDataType = 'int' | 'bool';

export interface FeatureFlagDescription {
  Name: string,
  DataType: FlagDataType
  CanBeSetByNetworkAdmin: boolean,
  IsEnterprise: boolean,
  DefaultValue: string | number | boolean
}

export interface FeatureFlagOverride {
  NetworkName: string,
  AccountName: string,
  Level: string,
  LevelId: string,
  Value: string | boolean | number
}
