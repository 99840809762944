<div class="location-dialog">
  <div class="displaygroup-select">
    <span>Select Display Group</span>
    <p-dropdown class="selected-displaygroup" 
     [options]="displayGroups"
     [optionLabel]="'DisplayGroupName'"
     [(ngModel)]="displayGroupInEdit"
     [appendTo]="'body'"
     [virtualScroll]="true" 
     itemSize="50"
     autoDisplayFirst="false"
     [style]="{ 'width':'100%' }"
     placeholder="Select Display Group"
     [filter]="'true'"
     filterBy="value.DisplayGroupName">
      <ng-template let-item pTemplate="item"> 
          <div class="display-group-select-item" [ngClass]="{ unmapped: !isDisplayGroupMapped(item.value) }">
            <div>{{ item.value.DisplayGroupName }}</div>
            <div class="company-name">{{ getCompanyNameForDisplayGroup(item.value) }}</div>
          </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="unit-details">
    <div class="column">
      <span>Unit Count</span>
      <input class="unit-count" type="number"
        [ngModel]="displayGroupInEdit?.DeviceCount"
        (change)="unitCountChange($event)"/>
    </div>
    <div class="column">
      <span>Cost Per Unit</span>
      <input class="unit-cost" type="text" disabled="true" value="$25" />
    </div>
  </div>
  <div class="company-select">
    <span>Select Company</span>
    <p-dropdown class="selected-company"
      [options]="companies"
      [optionLabel]="'Name'"
      [(ngModel)]="company"
      [appendTo]="'body'"
      [filter]="'true'"
      filterBy="value.Name,value.Organization,value.Address,value.CompanyReferenceId"
      [virtualScroll]="true" 
      itemSize="50"
      autoDisplayFirst="false"
      [style]="{ 'width': '100%' }"
      placeholder="Select Company"
      (onChange)="companyChange()">
        <ng-template let-item pTemplate="item"> 
          <div class="company-select-item">
            <div class="company-item-name">{{ item.value.Name }} ({{ item.value.CompanyReferenceId }})</div>
            <div class="company-address">{{ item.value.Address }}</div>
          </div>
        </ng-template>
    </p-dropdown>
  </div>
