import { Component, OnInit, Input } from '@angular/core';
import { LogsBackendService } from 'app/services/logs-backend.service';
import { AuditEntry } from 'app/models/constants';

@Component({
  selector: 'ep-adserver-audits',
  templateUrl: './adserver-audits.component.html',
  styleUrls: ['./adserver-audits.component.scss']
})
export class AdserverAuditsComponent implements OnInit {

  predicate: string;
  @Input() limit: string = "1000";
  buttonDisabled: boolean;
  descending: boolean = true;
  audits: AuditEntry[];

  constructor(private logsBackendService: LogsBackendService) { }

  ngOnInit() {
  }

  async fetchAudits() {
    this.buttonDisabled = true;
    try {
      this.audits = await this.logsBackendService.fetchAudits(this.predicate || "", parseInt(this.limit, 10), !this.descending).toPromise();
    }
    catch (error) {
      this.audits = [];
    }
    this.buttonDisabled = false;
  }
}
