import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable()
export class InvalidTokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse && this.isInvalidTokenResponse(event)) {
          window.location.href = environment.loginUrl;
        }
        return event;
      })
    )
  }

  isInvalidTokenResponse(event: HttpEvent<any>): boolean {
    return event.type === HttpEventType.Response &&
      event.body &&
      (event.body.ErrorCode === 'InvalidAccessToken' || event.body.ErrorCode === 'NoAccessToken');
  }
}
