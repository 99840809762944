import { Component, OnInit } from '@angular/core';
import { DeploymentMonitorBackendService } from '../services/deployment-monitor-backend.service';
import { DeploymentMonitorApplication } from '../models/constants';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'ep-adserver-changelog',
  templateUrl: './adserver-changelog.component.html',
  styleUrls: ['./adserver-changelog.component.scss']
})
export class AdserverChangelogComponent implements OnInit {

  applications: DeploymentMonitorApplication[];
  environments: SelectItem[];
  environmentApplications: SelectItem[];
  environmentApplicationInstances: SelectItem[];
  selectedEnvironment: SelectItem;
  selectedEnvironmentApplication: SelectItem;
  selectedEnvironmentApplicationInstance: SelectItem;
  releaseNotes: string;
  buttonDisabled: boolean;

  constructor(private deploymentMonitorBackendService: DeploymentMonitorBackendService) { }

  async ngOnInit() {
    this.applications = await this.deploymentMonitorBackendService.fechApplications().toPromise();
    this.updateEnvironments();
  }

  environmentChanged(selected: SelectItem) {
    this.selectedEnvironment = selected;
    this.updateApplications();
  }

  environmentApplicationChanged(selected: SelectItem) {
    this.selectedEnvironmentApplication = selected;
    this.updateInstances();
  }

  environmentApplicationInstanceChanged(selected: SelectItem) {
    this.selectedEnvironmentApplicationInstance = selected;
  }

  updateEnvironments() {
    this.environments = Array.from(new Set(this.applications.map(_ => _.Environment)))
      .sort((a, b) => a.length > b.length ? 1 : -1)
      .map(_ => <SelectItem>{ label: _, value: _ });
    this.selectedEnvironment = this.environments[0];
    this.updateApplications();
  }

  updateApplications() {
    this.environmentApplications = Array.from(new Set(this.applications.filter(_ => _.Environment == this.selectedEnvironment.value).map(_ => _.Application)))
      .sort()
      .map(_ => <SelectItem>{ label: _, value: _ });
    this.selectedEnvironmentApplication = this.environmentApplications[0];
    this.updateInstances();
  }

  updateInstances() {
    this.environmentApplicationInstances = Array.from(new Set(this.applications.filter(_ => _.Environment === this.selectedEnvironment.value && _.Application == this.selectedEnvironmentApplication.value).map(_ => _.Instance)))
      .sort()
      .map(_ => <SelectItem>{ label: _, value: _ });
    this.selectedEnvironmentApplicationInstance = this.environmentApplicationInstances[0];
  }

  async fetchReleaseNotes() {
    this.buttonDisabled = true;
    try {
      this.releaseNotes = await this.deploymentMonitorBackendService.fetchReleaseNotes(this.selectedEnvironment.value, this.selectedEnvironmentApplication.value, this.selectedEnvironmentApplicationInstance.value).toPromise();
    }
    catch (error) {
      this.releaseNotes = JSON.stringify(error, null, 2);
    }

    this.buttonDisabled = false;
  }
}
