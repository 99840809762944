import {
  BackendResponse,
  Command
} from '../models/constants';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommandsService {

  constructor(private http: HttpClient) { }

  getTargets(instance: string): Observable<string[]> {
    return this.http.get<BackendResponse<string[]>>(`${environment.backendUrl}/${instance}/v1/commands/targets`, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<string[]>) => response.Result)
    );
  }

  getCommands(instance: string, type: string): Observable<Command[]> {
    return this.http.get<BackendResponse<Command[]>>(`${environment.backendUrl}/${instance}/v1/commands/commands?target=${type}`, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<Command[]>) => response.Result)
    );
  }

  executeCommands(instance: string, type: string, command: string, request: any): Observable<any> {
    return this.http.post(`${environment.backendUrl}/${instance}/v1/commands/execute?target=${type}&method=${command}`, request, {
      withCredentials: true
    });
  }
}
