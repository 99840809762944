export const WEWORK_MAPPINGS_URL = `invoice/displaygroup/mappings`;
export const WEWORK_COMPANIES_URL = `invoice/companydatas`;
export const WEWORK_SEND_INVOICE_URL = `invoice/send`;
export const WEWORK_DEFAULT_COST_PER_UNIT = 25;

export interface WeWorkDisplayGroup {
  CompanyId: string,
  DeviceCount: number,
  DisplayGroupId: string,
  DisplayGroupName: string,
};

export interface WeWorkCompany {
  Address: string,
  Created: string,
  Id: string,
  IsDeleted: boolean,
  LastSaved: string,
  Name: string,
  Organization: string,
  CompanyReferenceId: string,
  AddressReferenceId: string
};

export interface WeWorkMapping {
  DisplayGroupId: string,
  CompanyId: string
}

export interface SaveMappingsRequest {
  DisplayGroupMappings: WeWorkMapping[]
}

export interface WeWorkInvoiceRequest {
  Invoices: WeWorkCompanyBill[];
  InvoiceDate: Date | string;
}

export interface WeWorkCompanyBill {
  CompanyId: string;
  DeviceCount: number;
}
