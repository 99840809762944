<div class="card">
  <span class="title">
    <h1>Feature flags</h1>
    <button class="button primary" (click)="openDialog()">Add New</button>
  </span>
  
  <table mat-table [dataSource]="datasource">
  
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let flag; columns: columnsToDisplay" class="table-row"></tr>
  
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let flag"
          (click)="openDialog(flag)"
          class="table-item-name"> {{ flag.Name }}</td>
    </ng-container>
  
    <ng-container matColumnDef="IsEnterprise">
      <th mat-header-cell *matHeaderCellDef>Is Enterprise </th>
      <td mat-cell *matCellDef="let flag" (click)="openDialog(flag)"><ep-boolean-icon [value]="flag.IsEnterprise"></ep-boolean-icon></td>
    </ng-container>
  
    <ng-container matColumnDef="CanBeSetByNetworkAdmin">
      <th mat-header-cell *matHeaderCellDef>Can Be Set By Network Admin</th>
      <td mat-cell *matCellDef="let flag" (click)="openDialog(flag)">
        <ep-boolean-icon [value]="flag.CanBeSetByNetworkAdmin"></ep-boolean-icon>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="DataType">
      <th mat-header-cell *matHeaderCellDef>Data Type</th>
      <td mat-cell *matCellDef="let flag" (click)="openDialog(flag)"> {{ flag.DataType }} </td>
    </ng-container>
  
    <ng-container matColumnDef="DefaultValue">
      <th mat-header-cell *matHeaderCellDef>Default Value</th>
      <td mat-cell *matCellDef="let flag"
          (click)="openDialog(flag)"
          class="default-value">
        <ep-boolean-icon *ngIf="flag.DataType === 'bool'" [value]="flag.DefaultValue"></ep-boolean-icon>
        <span class="default-value" *ngIf="flag.DataType === 'int'"> {{ flag.DefaultValue }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Delete</th>
      <td mat-cell *matCellDef="let flag"
        (click)="deleteFlag(flag, $event)">
          <i class="icon icon__cell btn ion-ios-trash"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="overrides">
      <th mat-header-cell *matHeaderCellDef>Overrides</th>
      <td [routerLink]="flag.Name"
        mat-cell *matCellDef="let flag">
          <i class="icon__cell icon btn ion-ios-eye"></i>
      </td>
    </ng-container>
  </table>
</div>

<p-dialog [(visible)]="flagInEdit"
  [modal]="true"
  [dismissableMask]="true"
  [header]="getDialogTitle()"
  [closable]="true"
  (onHide)="closeDialog()">
  <ep-flag-dialog
    [flag]="flagInEdit"
    [editMode]="editing">
  </ep-flag-dialog>
  <p-footer class="dialog-footer">
    <button class="button primary" (click)="saveFlag(flagInEdit)">Deploy</button>
    <button class="button primary" (click)="closeDialog()">Cancel</button>
  </p-footer>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>