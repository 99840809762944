import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { FeatureFlagOverride } from '../../models/constants';

@Component({
  selector: 'ep-flag-overrides',
  templateUrl: './flag-overrides.component.html',
  styleUrls: ['./flag-overrides.component.scss']
})
export class FlagOverridesComponent implements OnInit {

  flagName: string;
  dataSource: MatTableDataSource<FeatureFlagOverride>;
  columnsToDisplay = ['NetworkName', 'AccountName', 'Value']

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.flagName = this.route.snapshot.paramMap.get('flagName');
    const overrides = this.route.snapshot.data.overrides;
    console.log(overrides);
    this.dataSource = new MatTableDataSource(overrides);
  }

  isValueBoolean(override: FeatureFlagOverride): boolean {
    return override.Value === true || override.Value === false ||
      (override.Value as string).toLowerCase() === 'true' || (override.Value as string).toLowerCase() === 'false';
  }

}
