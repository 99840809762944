<div class="card">
  <div class="platform-selector">
    <h2 class = "row">Resources</h2>
    <div class="ui-g">
      <div class="ui-g-8">
        <div class="ui-inputgroup"  id="owlDateRange">
          <span class="ui-inputgroup-addon">Date range: </span>
          <input (dateTimeChange)="setRange()" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [(ngModel)]="dateTimeRange" [selectMode]="'range'">
          <owl-date-time #dt1 ></owl-date-time>
        </div>
      </div>
    </div>
    <div class="ui-g">
        <div class="ui-g-4">
          <div class="ui-inputgroup">
            <span id="accountId" class="ui-inputgroup-addon">AccountId:</span>
            <input [(ngModel)]="accountId">
          </div>
        </div>
      <div class="ui-g-4">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">FileType:</span>
            <p-dropdown [options]="fileType" [ngModel]="selectedFiletype" (onChange)="fileTypeChange($event.value)" optionLabel="label"></p-dropdown>
          </div>
      </div>
      <div class="ui-g-4">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Status:</span>
          <p-dropdown [options]="status" [ngModel]="selectedStatus" (onChange)="statusChange($event.value)" optionLabel="label"></p-dropdown>
        </div>
      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-4">
        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon">Limit:</span>
          <p-dropdown [options]="limit" [ngModel]="selectedLimit" (onChange)="limitChange($event.value)"optionLabel="label"></p-dropdown>
        </div>
      </div>
      <div class="ui-g-8">
        <button id="filterButton" class="button" (click)="filter()">Filter</button>
      </div>
    </div>
  </div>
</div>
<p-table [value]="resources" autoLayout="true">
  <ng-template pTemplate="header">
    <tr>
      <th style="width:5%">ResourceId</th>
      <th style="width:20%">Name</th>
      <th style="width:5%">Status</th>
      <th style="width:5%">Progress</th>
      <th style="width:10%">Created</th>
      <th style="width:5%">TimeSinceLastSaved</th>
      <th style="width:5%">FileType</th>
      <th style="width:30%">Error</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-resource >
    <tr (click)="openModal('resource-details', resource)">
      <td>{{resource.Id}}</td>
      <td>{{resource.Name}}</td>
      <td><span [ngClass]="{ 
        'failed': resource.Status ==='Failed',
        'succeeded': resource.Status ==='Succeeded',
        'inProgress': resource.Status ==='InProgress'}">{{resource.Status}}</span></td>
      <td>{{resource.ProgressPct}}%</td>
      <td>{{resource.Created}}</td>
      <td>{{resource.TimeSinceLastSaved}}</td>
      <td>{{resource.FileType}}</td>
      <td>{{resource.Error | slice:0:120}}</td>
    </tr>
  </ng-template>
</p-table>
<div class="btn-section">
  <button [attr.disabled]="prevButtonDisabled ? true : null" (click)="GoToPrevPage()">Prev</button>
  <button [attr.disabled]="nextButtonDisabled ? true : null" (click)="GoToNextPage()">Next</button>
</div>
<jw-modal id="resource-details">
  <div>
    <h2>Resource details:</h2>
      <div *ngIf=resourceDetails>
        <table>
          <tr>
            <td>ResourceId:</td>
            <td>{{resourceDetails.Id}}</td>
          </tr>
          <tr>
            <td>Name:</td>
            <td>{{resourceDetails.Name}}</td>
          </tr>
          <tr>
            <td>AccountId:</td>
            <td>{{resourceDetails.AccountId}}</td>
          </tr>
          <tr>
            <td>AssetId:</td>
            <td>{{resourceDetails.AssetId}}</td>
          </tr>
          <tr>
            <td>UserId:</td>
            <td>{{resourceDetails.UserId}}</td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>{{resourceDetails.Status}}</td>
          </tr>
          <tr>
            <td>FileType:</td>
            <td>{{resourceDetails.FileType}}</td>
          </tr>
          <tr>
            <td>Error:</td>
            <td>{{resourceDetails.Error}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf="resourceDetails && resourceDetails.FileType === 'Video'">
        <h3>Video additional info:</h3>
        <table>
          <tr>
            <td>NormalResolutionDuration (ms):</td>
            <td>{{resourceDetails.VideoNormalResolutionDuration}}</td>
          </tr>
          <tr>
            <td>HighResolutionDuration (ms):</td>
            <td>{{resourceDetails.VideoHighResolutionDuration}}</td>
          </tr>
          <tr>
            <td>TotalEncodingTime (sec):</td>
            <td>{{resourceDetails.TotalEncodingTime}}</td>
          </tr>
        </table>
      </div>
      <div class="button-wrapper">
        <button class="button" (click)="closeModal('resource-details');">Close</button>
      </div>
  </div>
</jw-modal>