import { Component, OnInit } from '@angular/core';
import { ResourceMonitoringService } from '../services/resource-monitoring.service';
import { SelectItem } from 'primeng/components/common/selectitem';
import { ResourceDetails, MediaMonitoringInfo } from 'app/models/constants';
import { ModalService } from './modal/modal.service';
import { toFormattedDateString} from 'app/services/utils';

@Component({
  selector: 'ep-adserver-resource-monitoring',
  templateUrl: './adserver-resource-monitoring.component.html',
  styleUrls: ['./adserver-resource-monitoring.component.scss']
})
export class AdserverResourceMonitoringComponent implements OnInit {
  
  environments: SelectItem[] = ["Dev", "Staging", "Production"].map(_ => <SelectItem>{ label: _, value: _ });
  selectedEnvironment: SelectItem;
  limit: SelectItem[] = ["5", "10", "20", "50", "100"].map(_ => <SelectItem>{ label: _, value: _ });
  selectedLimit: SelectItem;
  fileType: SelectItem[] = ["All", "Image", "Video", "PowerPoint", "Excel", "Word", "PDF"].map(_ => <SelectItem>{ label: _, value: _ });
  selectedFiletype: SelectItem;
  status: SelectItem[] = ["All", "InProgress", "Succeeded", "Failed"].map(_ => <SelectItem>{ label: _, value: _ });
  selectedStatus: SelectItem;
  accountId: string;

  resources: ResourceDetails[];
  resourceDetails: ResourceDetails;
  response: MediaMonitoringInfo;
  prevButtonDisabled: boolean;
  nextButtonDisabled: boolean;

  dateTimeRange: Date[];
  startDate: string;
  endDate: string;

  constructor(
    private ResourceMonitoringService: ResourceMonitoringService,
    private modalService: ModalService) { }

  async ngOnInit() {
    
    var now = new Date();
    this.dateTimeRange = [
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() -1, now.getMinutes(), now.getSeconds()),
      now,
    ];

    this.endDate = toFormattedDateString(this.dateTimeRange[1]);
    this.startDate = toFormattedDateString(this.dateTimeRange[0]);

    this.selectedLimit = this.limit[2];
    this.selectedFiletype = this.fileType[0];
    this.selectedStatus = this.status[0];

    this.response = await this.ResourceMonitoringService.getResources(this.startDate ,this.endDate , this.selectedLimit.value, null, null, null, null, null).toPromise();
    this.resources = this.response.MediaMonitoringInfo;
    this.setPagingButtons();
  }

  setPagingButtons()
  {
    this.prevButtonDisabled = !this.response.Pagination.HasPrev;
    this.nextButtonDisabled = !this.response.Pagination.HasNext;
  }

  limitChange(selected: SelectItem)
  {
    this.selectedLimit = selected;
  }

  fileTypeChange(selected: SelectItem)
  {
    this.selectedFiletype = selected;
  }

  statusChange(selected: SelectItem)
  {
    this.selectedStatus = selected;
  }

  setRange()
  {
    this.endDate = toFormattedDateString(this.dateTimeRange[1]);
    this.startDate = toFormattedDateString(this.dateTimeRange[0]);
  }

  async filter()
  {
    this.response = await this.ResourceMonitoringService.getResources(
      this.startDate,
      this.endDate,
      this.selectedLimit.value,
      this.selectedFiletype.value,
      this.selectedStatus.value,
      this.accountId).toPromise();
    this.resources = this.response.MediaMonitoringInfo;

    this.setPagingButtons();
  }

  async GoToNextPage()
  {
    var next = this.resources[this.resources.length-1].Id;
    this.response = await this.ResourceMonitoringService.getResources(
      this.startDate,
      this.endDate,
      this.selectedLimit.value,
      this.selectedFiletype.value,
      this.selectedStatus.value,
      this.accountId,
      next).toPromise();
      
    this.resources = this.response.MediaMonitoringInfo;
    this.setPagingButtons();
  }

  async GoToPrevPage()
  {
    var prev = this.resources[0].Id;
    this.response = await this.ResourceMonitoringService.getResources(
      this.startDate,
      this.endDate,
      this.selectedLimit.value,
      this.selectedFiletype.value,
      this.selectedStatus.value,
      this.accountId,
      null,
      prev).toPromise();

    this.resources = this.response.MediaMonitoringInfo;
    this.setPagingButtons();
  }

  openModal(id: string, resource: ResourceDetails) {
    this.resourceDetails = resource;
    this.modalService.open(id);
  }

  closeModal(id: string) {
      this.modalService.close(id);
  }
}
