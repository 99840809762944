import * as moment from 'moment';
import {
  AccountInfo,
  App,
  DeploymentSource,
  DeployTarget,
  DeviceDetils,
  Network,
  Platform,
  TargetLevel,
  Venue
  } from '../../models/constants';
import { BackendService } from '../../services/backend.service';
import { Component, Input, OnInit } from '@angular/core';
import { DeploymentService } from '../../services/deployment.service';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'ep-deployment-dialog',
  templateUrl: './deployment-dialog.component.html',
  styleUrls: ['./deployment-dialog.component.scss']
})
export class DeploymentDialogComponent implements OnInit {

  @Input()
  deployments: App[];

  deployWhen = 'now';
  deployDate = null;
  devices: DeviceDetils[];
  networks: Network[];
  accounts: AccountInfo[];
  levelOptions;

  uploadPending = false;
  errors: string[];

  get source(): DeploymentSource {
    return this.deploymentService.source || 'build';
  }

  get targetLevel(): TargetLevel {
    return this.deploymentService.targetLevel;
  }

  get app(): App {
    return this.deploymentService.app || null;
  }

  get platform(): Platform {
    return this.deploymentService.platform;
  }

  get resource(): File {
    return this.deploymentService.resource;
  }

  levelSelectOptions: any[] = [
    { label: 'Enplug', value: 'Enplug' },
    { label: 'Network', value: 'Network' },
    { label: 'Account', value: 'Account' },
    { label: 'Edu', value: 'Edu' },
  ]

  sourceSelectOptions: any[] = [
    { label: 'Build', value: 'build' },
    { label: 'Resource', value: 'resource' },
  ]

  constructor(
    private backend: BackendService,
    private upload: UploadService,
    private deploymentService: DeploymentService) {
      this.deploymentService.error.subscribe((errors) => {
        this.errors = errors;
      });
    }

  async ngOnInit() {
    if (this.deploymentService.source == null) {
      this.deploymentService.source = this.source;
    }
    await this.setDeployTargetToDefault();
  }

  async fetchNetworks() {
    this.networks = (await this.backend.fetchNetworks().toPromise()).Result;
    this.levelOptions = this.networks.filter(network => !network.IsMaster).map(network => {
      return {
        label: network.Name,
        value: this.getDeploymentTarget(network.Name, network.Id, 'Network')
      }
    });
  }

  async fetchAccounts() {
    this.accounts = (await this.backend.fetchAccounts().toPromise()).Result;
    this.levelOptions = this.accounts.filter(account => account.AccountType === 'Venue').map(account => {
      return {
        label: account.Name,
        value: this.getDeploymentTarget(account.Name, account.Id, 'Account')
      }
    });
  }

  async fetchDevices() {
    this.devices = (await this.backend.fetchDevices().toPromise()).Result.EduStatuses;
    this.levelOptions = this.devices.map(device => {
      return {
        label: device.Edu.Name,
        value: this.getDeploymentTarget(device.Edu.Name, device.Edu.Id, 'Edu')
      }
    });
  }

  onSourceChanged(source: DeploymentSource) {
    this.deploymentService.source = source;
  }

  onAppSelected(app: App) {
    this.deploymentService.app = Object.assign({}, app);
    this.deploymentService.resource = null;
    this.deploymentService.fileKey = null;
  }

  async onLevelSelected(targetLevel: TargetLevel) {
    this.levelOptions = null;
    this.deploymentService.targetLevel = targetLevel;
    this.deploymentService.target = this.getDeploymentTarget(targetLevel, null, targetLevel);

    switch (targetLevel) {
      case 'Enplug':
        this.fetchNetworks();
        break;
      case 'Network':
        this.fetchNetworks();
        break;
      case 'Account':
        this.fetchAccounts();
        break;
      case 'Edu':
        this.fetchDevices();
        break;
    }
  }

  async setDeployTargetToDefault() {
    this.deploymentService.target = this.getDeploymentTarget('All devices', null, 'Enplug');
    await this.fetchNetworks();
    this.deploymentService.targetLevel = 'Enplug';
  }

  onLevelIdSelected(target: DeployTarget) {
    this.deploymentService.target = target;
  }

  onExcludedNetworksSelected(targets: DeployTarget[]) {
    this.deploymentService.excludedNetworkIds = targets.map(target => target.LevelId);
  }

  onDeployTimeChanged() {
    this.deploymentService.deployTime = moment(this.deployDate).format('HH:mm:ss');
  }

  onAppVersionChanged(version: string) {
    if (this.deploymentService.app) {
      this.deploymentService.app.AppVersion = parseInt(version, 10) || null;
    }
  }

  onBuildVersionChanged(version: string) {
    if (this.deploymentService.app) {
      this.deploymentService.app.BuildVersion = parseInt(version, 10) || null;
    }
  }

  async handleFileInput(files: FileList) {
    if (files.length > 0) {
      this.deploymentService.resource = files[0];
      this.uploadPending = true;
      const response = await this.upload.uploadResource(this.resource).toPromise();
      if (response.Success) {
        this.deploymentService.fileKey = response.Result;
      } else {
        this.deploymentService.resource = null;
        this.deploymentService.fileKey = null;
      }
      this.uploadPending = false;
    }
  }

  getDeploymentTarget(name: string, levelId: string, level: string) {
    const value: DeployTarget = {
      Name: name,
      LevelId: levelId,
      Level : level
    };

    return value;
  }

  getUploadButtonText() {
    if (this.uploadPending) {
      return 'Uploading...';
    } else if (this.resource) {
      return this.resource.name;
    } else {
      return 'Upload file';
    }
  }

}
