import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FeatureFlagOverride } from '../models/constants';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagOverridesResolver {
  constructor(private flagService: FeatureFlagsService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<FeatureFlagOverride[]> {
    const name = route.paramMap.get('flagName');
    if (name) {
      return this.flagService.fetchFeatureFlagsOverrides(name);
    } else {
      return Promise.resolve([]);
    }
  }
}
