import { Component, } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'ep-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private user: UserService) {

  }

  isWeWorkBillingVisible(): boolean {
    return this.user.isWeWorkBillingEnabled();
  }
}
