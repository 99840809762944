import { Component, OnInit, Input } from '@angular/core';
import { LogsBackendService } from 'app/services/logs-backend.service';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'ep-adserver-logs',
  templateUrl: './adserver-logs.component.html',
  styleUrls: ['./adserver-logs.component.scss']
})
export class AdserverLogsComponent implements OnInit {

  predicate: string;
  @Input() limit: string = "1000";
  buttonDisabled: boolean;
  descending: boolean = true;
  logs: LogEntryView[];
  easyMode: boolean = false;
  filters: Filter[] = [
    { Label: "AccountId", Field: "[Properties.AccountId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "AppId", Field: "[Properties.AppId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "AssetId", Field: "[Properties.AssetId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "Container", Field: "[Container]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "CorrelationId", Field: "[Properties.CorrelationId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "FeedId", Field: "[Properties.FeedId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "Level", Field: "[Level]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "Log", Field: "[Log]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "RequestPath", Field: "[Properties.RequestPath]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "SimpleName", Field: "[Properties.SimpleName]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "SocialItemId", Field: "[Properties.SocialItemId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "TokenUserName", Field: "[Properties.TokenUserName]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "TokenUserId", Field: "[Properties.TokenUserId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "TraceId", Field: "[Properties.RequestTraceId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "UserAccountId", Field: "[Properties.UserAccountId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "UserActivity", Field: "[Properties.UserActivity]", Value: true, Type: "bool", Operator: "==", Enabled: true },
    { Label: "VenueId", Field: "[Properties.VenueId]", Value: null, Type: "string", Operator: "==", Enabled: false },
    { Label: "From", Field: "[Timestamp]", Value: null, Type: "datetime", Operator: ">=", Enabled: false },
    { Label: "To", Field: "[Timestamp]", Value: null, Type: "datetime", Operator: "<=", Enabled: false }
  ];
  filterToAdd: Filter;
  fieldReplacements ={
    Log: "Properties.SourceContext",
    Container: "Properties.Container",
    Username: "Properties.TokenUserName",
    Time: "Timestamp",
    Message: "RenderedMessage"
  }

  constructor(private logsBackendService: LogsBackendService) { }

  ngOnInit() {
    this.setDefaultFilterToAdd();
  }

  getFiltersToAdd() {
    return this.filters.filter(_ => !_.Enabled);
  }
  removeFilter(index) {
    this.filters[index].Enabled = false;
  }
  addFilter() {
    this.filterToAdd.Enabled = true;
    this.setDefaultFilterToAdd();
  }
  setDefaultFilterToAdd() {
    let filters = this.getFiltersToAdd();
    this.filterToAdd = filters.length > 0 ? filters[0] : null;
  }
  async fetchLogs() {
    this.buttonDisabled = true;
    try {
      this.logs = (await this.logsBackendService.fetchLogs(this.getPredicate() || "", parseInt(this.limit, 10), !this.descending).toPromise())
        .map(_ => {
          const x = _ as LogEntryView;
          x.Log = x.Properties.SourceContext;
          x.Container = x.Properties.Container;
          x.Username = x.Properties.TokenUserName;
          delete x.Properties.SourceContext;
          delete x.Properties.Container;
          delete x.Properties.TokenUserName;
          return x;
        });

    }
    catch (error) {
      this.logs = [];
    }
    this.buttonDisabled = false;
  }

  getPredicate(): string {
    if (this.easyMode){
      this.predicate = "";

      this.filters.filter(_ => _.Enabled && _.Value != null && _.Value != "").forEach(_ =>{
        if (this.predicate != '') {
          this.predicate = this.predicate + ' && ';
        }

        this.predicate = this.predicate + _.Field + ' ' + _.Operator + ' ';
        if (_.Type == 'string') {
          this.predicate = this.predicate + '"' + _.Value + '"';
        }
        else if (_.Type == 'int') {
          this.predicate = this.predicate + _.Value;
        }
        else if (_.Type == 'bool') {
          this.predicate = this.predicate + _.Value;
        }
        else if (_.Type == 'datetime') {
          this.predicate = this.predicate + 'date(' + _.Value.toISOString() + ')';
        }
      });
    }

    return this.replaceFieldNames(this.predicate);
  }

  replaceFieldNames(predicate: string) : string {
    if (!predicate) {
      return predicate;
    }
    let end = predicate.length;
    let fields = [];
    var escaped = false;
    for (var i = 0; i < end; ++i) {
      var c = predicate[i];
      if (c === '[') {
        let fieldStart = i;
        ++i;
        for (; i < end; ++i) {
          c = predicate[i];
          if (c === '\\') {
            escaped = !escaped;
          }
          else if (!escaped && c === ']') {
            let fieldEnd = i;
            fields.push([fieldStart, fieldEnd]);
            break;
          }
        }
      }
      else if (c === '"' || c === "'")
      {
        ++i;
        for (; i < end; ++i) {
          c = predicate[i];
          if (c === '\\') {
            escaped = !escaped;
          }
          else if (!escaped && (c === '"' || c === "'")) {
            break;
          }
        }
      }
    }

    var newPredicate = "";
    var nextToCopy = 0;
    for (var i = 0; i < fields.length; ++i) {
      let item = fields[i];
      var field = predicate.substring(item[0] + 1, item[1]);
      if (this.fieldReplacements.hasOwnProperty(field)) {
        newPredicate = newPredicate + predicate.substring(nextToCopy, item[0] + 1) + this.fieldReplacements[field];
        nextToCopy = item[1];
      }
    }

    if (nextToCopy < predicate.length) {
      newPredicate = newPredicate + predicate.substring(nextToCopy);
    }

    return newPredicate;
  }
}

export interface LogEntryView {
  Timestamp: string,
  Log: string,
  Container: string,
  Properties: any,
  Level: string,
  RenderedMessage: string,
  Exception: string,
  Username: string
}
export interface Filter {
  Label: string,
  Field: string,
  Value: any,
  Type: string,
  Operator: string,
  Enabled: boolean
}
