<ul class="navbar">
  <li [routerLink]="'deployment'" [routerLinkActive]="'active'">Deployments</li>
  <li [routerLink]="'changelog'" [routerLinkActive]="'active'">Changelog</li>
  <li [routerLink]="'logs'" [routerLinkActive]="'active'">Logs</li>
  <li [routerLink]="'audits'" [routerLinkActive]="'active'">Audits</li>
  <li [routerLink]="'adserver-deployment'" [routerLinkActive]="'active'">AdServer deployment</li>
  <li [routerLink]="'commands'" [routerLinkActive]="'active'">Commands</li>
  <li [routerLink]="'resource-monitoring'" [routerLinkActive]="'active'">Resource monitoring</li>
  <li [routerLink]="'feature-flags'" [routerLinkActive]="'active'">Feature Flags</li>
  <li *ngIf="isWeWorkBillingVisible()" [routerLink]="'wework'" [routerLinkActive]="'active'">WeWork Billing</li>
</ul>
<router-outlet></router-outlet>
