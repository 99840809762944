<div class="confirmation-dialog">
  <div class="info">Please confirm whether you want to send this invoice:</div>
  <div class="datepicker">
    <span>Invoice Date: </span>
    <p-calendar 
      [ngModel]="invoiceData?.InvoiceDate"
      (ngModelChange)="onDateChange($event)"></p-calendar>
  </div>
  <div class="invoice-container">
    <div class="invoice-item" *ngFor="let bill of invoiceData?.Invoices">
      <div class="company-name">{{ mapToCompanyName(bill.CompanyId) }}</div>
      <input class="device-count" type="number" [(ngModel)]="bill.DeviceCount">
      <div class="price">Price: {{ bill.DeviceCount * defaultPrice | currency}}</div>
    </div>
  </div>
  <div class="total">Total: {{ calculateTotal() | currency }}</div>
</div>