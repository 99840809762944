import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * Whether WeWork Billing Tool is enabled for currently logged user.
   */
  isWeWorkBillingEnabled(): boolean {
    const username = this.getUserName();
    return environment.weworkBillingAllowedUsers.includes(username);
  }

  private getUserName(): string {
    return decodeURIComponent(this.getCookieValue('_ep-e'));
  }

  /**
   * Returns value of a cookie.
   * @param {string} key Cookie name
   */
  private getCookieValue(key): string {
    const result = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
    return result ? result.pop() : '';
  }
}
