import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResourceDetails, MediaMonitoringInfo, BackendResponse } from 'app/models/constants';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { backendDateToDate, humanizeDuration } from './utils';

@Injectable({
  providedIn: 'root'
})
export class ResourceMonitoringService {
  MediaMonitoringInfo: ResourceDetails[];
  constructor(private http: HttpClient) { }

  getResources(
    fromtime: string,
    totime:string,
    limit:number,
    filetype?:string,
    status?:string,
    accountid?:string,
    next?:string,
    prev?:string):Observable<MediaMonitoringInfo>
  {
    var request = `${environment.coreServerUrl}/monitoring/resources?fromtime=${fromtime}&totime=${totime}&limit=${limit}`;
    if (filetype != null && filetype != "All")
    {
      request += `&filetype=${filetype}`;
    }

    if (status != null && status != "All")
    {
      request += `&status=${status}`;
    }

    if (accountid != null)
    {
      request += `&accountid=${accountid}`;
    }

    if (prev != null)
    {
      request += `&prev=${prev}`;
    }

    if (next != null)
    {
      request += `&next=${next}`;
    }

    return this.http.get<BackendResponse<MediaMonitoringInfo>>(request, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<MediaMonitoringInfo>) => 
      { response.Result.MediaMonitoringInfo.map(entry => {
        entry.Created = backendDateToDate(entry.Created).format('YYYY/MM/DD HH:mm:ss');
        entry.TimeSinceLastSaved = humanizeDuration("-" + entry.TimeSinceLastSaved);
      });
      return response.Result
    }));
  }
}
