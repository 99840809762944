import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WeWorkDisplayGroup } from '../models/wework';
import { WeWorkService } from '../services/wework.service';

@Injectable({
  providedIn: 'root'
})
export class WeWorkDisplayGroupsResolver implements Resolve<WeWorkDisplayGroup[]> {
  constructor(private wework: WeWorkService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: Change it back when sample data is available on staging.
    return this.wework.fetchWeWorkDisplayGroups();
    // return [
    //   {
    //     CompanyId: 'NY18',
    //     CostPerUnit: 10,
    //     DeviceCount: 1,
    //     DisplayGroupId: 'displaygroup1',
    //     DisplayGroupName: 'A screen somewhere'
    //   },
    //   {
    //     CompanyId: 'NY18',
    //     CostPerUnit: 25,
    //     DeviceCount: 5,
    //     DisplayGroupId: 'displaygroup2',
    //     DisplayGroupName: 'Its somewhere else'
    //   },
    //   {
    //     CompanyId: null,
    //     CostPerUnit: 30,
    //     DeviceCount: 2,
    //     DisplayGroupId: 'displaygroup3',
    //     DisplayGroupName: 'This one is not mapped'
    //   },
    //   {
    //     CompanyId: 'LON03',
    //     CostPerUnit: 15,
    //     DeviceCount: 3,
    //     DisplayGroupId: 'displaygroup4',
    //     DisplayGroupName: 'London - 03'
    //   },
    // ]
  }
}
