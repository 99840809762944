export const environment = {
  production: false,
  coreServerUrl: 'https://staging-core.enplug.in/v1',
  monitoringUrl: 'https://staging-monitoring.enplug.in/v1',
  loginUrl: 'https://login.enplug.in',
  backendUrl: 'https://staging-backend.enplug.in',

  weworkBillingAllowedUsers: [
    // 'nanxi@enplug.com',
    // 'tina@enplug.com',
    // 'arek@enplug.com',
    // 'ben@enplug.com',
    // 'jesus@enplug.com',
    'ana.bedoya@spectrio.com',
    'cristian.hernandez@spectrio.com',
    'john.salazar@spectrio.com',
    'jose.duran@spectrio.com',
    'maria.celemin@spectrio.com'
  ],
};
