import {
  AuditEntry,
  BackendResponse,
  LogEntry
} from '../models/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { backendDateToDate } from './utils';

@Injectable({
  providedIn: 'root'
})
export class LogsBackendService {

  constructor(private http: HttpClient) { }

  fetchLogs(predicate: string, limit: number, ascending: boolean): Observable<LogEntry[]> {
    const url = `${environment.coreServerUrl}/logs/query?limit=${limit}&ascending=${ascending}&predicate=${encodeURIComponent(predicate)}`;
    return this.http.get<BackendResponse<LogEntry[]>>(url, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<LogEntry[]>) => response.Result),
      map((entries: LogEntry[]) => entries.map(entry => {
        entry.Timestamp = backendDateToDate(entry.Timestamp).format('YYYY/MM/DD hh:mm:ss');
        return entry;
      }))
    );
  }

  fetchAudits(predicate: string, limit: number, ascending: boolean): Observable<AuditEntry[]> {
    // tslint:disable-next-line: max-line-length
    const url = `${environment.coreServerUrl}/logs/queryaudits?limit=${limit}&ascending=${ascending}&predicate=${encodeURIComponent(predicate)}`;
    return this.http.get<BackendResponse<AuditEntry[]>>(url, {
      withCredentials: true
    }).pipe(
      map((response: BackendResponse<AuditEntry[]>) => response.Result),
      map((entries: AuditEntry[]) => entries.map(entry => {
        entry.Time = backendDateToDate(entry.Time).format('YYYY/MM/DD hh:mm:ss');
        return entry;
      }))
    );
  }
}
