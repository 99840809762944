import { Component, OnInit } from '@angular/core';
import { CommandsService } from '../services/commands.service';
import { SelectItem } from 'primeng/components/common/selectitem';
import { CommandParameter } from 'app/models/constants';

@Component({
  selector: 'ep-adserver-commands',
  templateUrl: './adserver-commands.component.html',
  styleUrls: ['./adserver-commands.component.scss']
})

export class AdserverCommandsComponent implements OnInit {

  instances: SelectItem[] = [
    {label: "AdServer", value: "adserver"},
    {label: "Alert", value: "alert"},
    {label: "Calendar", value: "calendar"},
    {label: "Devices", value: "devices"},
    {label: "EduMonitoring", value: "edumonitoring"},
    {label: "Facebook", value: "facebook"},
    {label: "Instagram", value: "instagram"},
    {label: "Mail", value: "mail"},
    {label: "ProofOfPlay", value: "proofofplay"},
    {label: "Resources", value: "resources"},
    {label: "Rss", value: "rss"},
    {label: "Slack", value: "slack"},
    {label: "SocialServer", value: "socialserver"},
    {label: "Teams", value: "teams"},
    {label: "Tripadvisor", value: "tripadvisor"},
    {label: "Twitter", value: "twitter"},
    {label: "Weather", value: "weather"},
    {label: "Yelp", value: "yelp"},
  ];
  types: SelectItem[];
  commands: SelectItem[];
  selectedInstance: SelectItem;
  selectedType: SelectItem;
  selectedCommand: SelectItem;
  request: string;
  result: string;
  buttonDisabled: boolean;
  showInput: boolean = false;
  serializedParameters: string;
  easyMode: boolean;
  easyModeDisabled: boolean;
  parameters: CommandParameter[];
  inputs: string[];
  easyModeConverters: any = {
    "System.String": (x) => x,
    "System.Int32": (x) => parseInt(x, 10),
    "System.Int64": (x) => parseInt(x, 10),
    "System.Single": (x) => parseFloat(x),
    "System.Double": (x) => parseFloat(x),
    "System.Boolean": (x) => x.toLowerCase() === "true",
    "Serilog.Events.LogEventLevel": (x) => x,
  };
  constructor(private commandsService: CommandsService) { }

  ngOnInit() {
    this.instanceChanged(this.instances[0]);
  }

  async instanceChanged(selected: SelectItem) {
    try {
      this.selectedInstance = selected;
      this.selectedType = null;
      this.selectedCommand = null;
      this.showInput = false;
      let returned = await this.commandsService.getTargets(this.selectedInstance.value).toPromise()
      this.types = returned.map(_ => <SelectItem>{ label: _.split(',')[0].split('.').pop(), value: _ });
      this.commands = [];
      this.selectedType = null;
      this.selectedCommand = null;
      this.typeChanged(this.types[0]);
    }
    catch (error) {
      this.types = null;
      this.typeChanged(null);
    }
  }

  async typeChanged(selected: SelectItem) {
    try {
      this.selectedType = selected;
      this.selectedCommand = null;
      this.showInput = false;
      if (selected == null) {
        this.commands = [];
        this.commandChanged(null);
      }
      else {
        let returned = await this.commandsService.getCommands(this.selectedInstance.value, this.selectedType.value).toPromise()
        this.commands = returned.map(_ => <SelectItem>{ label: _.Name, value: _ });
        this.commandChanged(this.commands[0]);
      }
    }
    catch (error) {
      this.commands = null;
      this.commandChanged(null);
    }
  }

  async commandChanged(selected: SelectItem) {
    this.selectedCommand = selected;
    if (selected == null) {
      this.showInput = false;
      this.inputs = [];
      this.parameters = [];
    }
    else {
      this.parameters = this.selectedCommand.value.Parameters;
      this.showInput = this.parameters && this.parameters.length > 0;
      if (this.showInput) {
        this.serializedParameters = JSON.stringify(this.parameters, null, 2);
        this.easyModeDisabled = this.parameters.findIndex(_ => !this.easyModeConverters.hasOwnProperty(_.Type)) != -1;
        if (!this.easyModeDisabled) {
          this.inputs = this.parameters.map(_ => _.DefaultValue ? _.DefaultValue + "" : null);
        }
      } 
      else {
        this.serializedParameters = null;
        this.easyModeDisabled = true;
      }
    }
  }
  
  async execute() {
    try
    {
      this.result = null;
      this.buttonDisabled = true;
      let returned = await this.commandsService.executeCommands(this.selectedInstance.value, this.selectedType.value, this.selectedCommand.value.Name, this.getRequest()).toPromise();
      if (returned.Result)
      {
        if (typeof returned.Result === "string") {
          this.result = <string> returned.Result;          
        }
        else if (typeof returned.Result === "number") {
          this.result = returned.Result + "";          
        }
        else {
          this.result = JSON.stringify(returned.Result, null, 2);
        }
      }
      else {
        this.result = JSON.stringify(returned, null, 2);
      }
    }
    catch (error) {
      this.result = error;
    }
    finally {
      this.buttonDisabled = false;
    }
  }

  getRequest() {
    if (!this.showInput) {
      return {};
    }

    if (!this.easyModeDisabled && this.easyMode) {
      return this.inputs.map((x, i) => this.easyModeConverters[this.parameters[i].Type](x));
    }

    if (this.request == null || this.request.trim() === "") {
      return {};
    }

    return JSON.parse(this.request);
  }
}
