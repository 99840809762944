import { AdserverAuditsComponent } from './adserver-audits/adserver-audits.component';
import { AdserverChangelogComponent } from './adserver-changelog/adserver-changelog.component';
import { AdserverCommandsComponent } from './adserver-commands/adserver-commands.component';
import { AdserverDeploymentComponent } from './adserver-deployment/adserver-deployment.component';
import { AdserverLogsComponent } from './adserver-logs/adserver-logs.component';
import { AdserverResourceMonitoringComponent } from './adserver-resource-monitoring/adserver-resource-monitoring.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser/';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DeploymentDialogComponent } from './deployment/deployment-dialog/deployment-dialog.component';
import { DeploymentsComponent } from './deployment/deployment.component';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { FlagDialogComponent } from './feature-flags/flag-dialog/flag-dialog.component';
import { FlagOverridesComponent } from './feature-flags/flag-overrides/flag-overrides.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InputMaskModule } from 'primeng/inputmask';
import { InvalidTokenInterceptor } from './services/invalid-token.interceptor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ModalComponent } from './adserver-resource-monitoring/modal/modal.component';
import { ModalModule } from './adserver-resource-monitoring/modal/modal.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { WeworkConfirmationDialogComponent } from './wework/wework-confirmation-dialog/wework-confirmation-dialog.component';
import { WeWorkInvoiceComponent } from './wework/wework-invoice/wework-invoice.component';
import { WeworkLocationDialogComponent } from './wework/wework-location-dialog/wework-location-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { BooleanIconComponent } from './feature-flags/boolean-icon/boolean-icon.component';

const MATERIAL_MODULES = [
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule
]

const PRIMENG_MODULES = [
  CalendarModule,
  CheckboxModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  InputMaskModule,
  MultiSelectModule,
  TableModule,
  TooltipModule,
  RadioButtonModule,
]

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    NoopAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ModalModule,
    ...MATERIAL_MODULES,
    ...PRIMENG_MODULES,

  ],
  declarations: [
    AdserverChangelogComponent,
    AdserverAuditsComponent,
    AdserverCommandsComponent,
    AdserverDeploymentComponent,
    AdserverLogsComponent,
    AdserverResourceMonitoringComponent,
    AppComponent,
    DeploymentsComponent,
    DeploymentDialogComponent,
    FeatureFlagsComponent,
    FlagDialogComponent,
    FlagOverridesComponent,
    ModalComponent,
    WeworkConfirmationDialogComponent,
    WeWorkInvoiceComponent,
    WeworkLocationDialogComponent,
    BooleanIconComponent,
  ],
  providers: [
    ConfirmationService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: InvalidTokenInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent],
  exports: [CommonModule]
})
export class AppModule { }
