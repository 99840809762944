import { Component, OnInit } from '@angular/core';
import { DeploymentMonitorBackendService } from '../services/deployment-monitor-backend.service';
import { DeploymentStatusEntry, Version, DeploymentPreReleaseInfo, YouTrackTask } from '../models/constants';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'ep-adserver-deployment',
  templateUrl: './adserver-deployment.component.html',
  styleUrls: ['./adserver-deployment.component.scss']
})
export class AdserverDeploymentComponent implements OnInit {

  environments: SelectItem[] = ["Dev", "Staging", "Production"].map(_ => <SelectItem>{ label: _, value: _ });
  selectedEnvironment: SelectItem;
  selectedTargetVersion: Version[];
  buttonDisabled: boolean;
  currentState: DeploymentStatusEntry[] = [];
  versions: Version[];
  deployed: YouTrackTaskView[];
  undeployed: YouTrackTaskView[];
  constructor(private deploymentMonitorBackendService: DeploymentMonitorBackendService) { }

  async ngOnInit() {
    this.versions = await this.deploymentMonitorBackendService.fetchVersions().toPromise();
    this.versions.sort((a, b) => {
      var partsA = a.Version.split("-", 2);
      var partsB = b.Version.split("-", 2);
      if (partsA[0] < partsB[0]) {
        return 1;
      }
      else if (partsA[0] > partsB[0]) {
        return -1;
      }

      if (parseInt(partsA[1], 10) < parseInt(partsB[1], 10)) {
        return 1;
      }

      return -1;
    });
    await this.environmentChanged(this.environments[0]);
  }

  async environmentChanged(selected: SelectItem) {
    this.selectedEnvironment = selected;
    this.currentState = await this.deploymentMonitorBackendService.fetchAdServerDeployment(selected.value).toPromise();

    this.selectedTargetVersion = [];
    this.deployed = null;
    this.undeployed = null;
  }

  versionChanged(selected: SelectItem) {
    this.deployed = null;
    this.undeployed = null;
  }

  async deploy() {
    var body = {};
    for (var i = 0; i < this.selectedTargetVersion.length; ++i) {
      var version = this.selectedTargetVersion[i];
      if (version) {
        body[this.currentState[i].Name] = version.Version;
      }
    }

    await this.deploymentMonitorBackendService.deploy(this.selectedEnvironment.label, body).toPromise();
    if (this.selectedEnvironment.label == "Staging") {
        await this.deploymentMonitorBackendService.runE2ETests().toPromise();
    }
    this.environmentChanged(this.selectedEnvironment);
  }

  async fetchChanges() {
    var body = {};
    for (var i = 0; i < this.selectedTargetVersion.length; ++i) {
      var version = this.selectedTargetVersion[i];
      if (version) {
        body[this.currentState[i].Name] = version.Version;
      }
    }

    var prerelease = await this.deploymentMonitorBackendService.fetchChanges(this.selectedEnvironment.label, body).toPromise();
    this.deployed = prerelease.Deployed.map(_ => {
      const item = _ as YouTrackTaskView;
      item.Notes = item.Comments.filter(x => x.Text.startsWith("release notes:")).map(x => x.Text.substr("release notes:".length).trim())
      return item;
    });
    this.undeployed = prerelease.Undeployed.map(_ => {
      const item = _ as YouTrackTaskView;
      item.Notes = _.Comments.filter(x => x.Text.startsWith("release notes:")).map(x => x.Text.substr("release notes:".length).trim())
      return item;
    });
  }
}

export interface YouTrackTaskView extends YouTrackTask {
  Notes: string[]
}
