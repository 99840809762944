import { Component, Input, OnInit } from '@angular/core';
import { FeatureFlagDescription, FlagDataType } from '../../models/constants';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'ep-flag-dialog',
  templateUrl: './flag-dialog.component.html',
  styleUrls: ['./flag-dialog.component.scss']
})
export class FlagDialogComponent {

  @Input()
  flag: FeatureFlagDescription;

  @Input()
  editMode: boolean;

  dataTypeDropdownOptions: SelectItem[] = [
    { label: 'Int', value: 'int' },
    { label: 'Bool', value: 'bool' }
  ]

  // tslint:disable-next-line: no-empty
  constructor() { }

  onDataTypeChange(event: { value: FlagDataType; }) {
    if (this.flag) {
      this.flag.DataType = event.value;
      if (event.value === 'bool') {
        this.flag.DefaultValue = this.flag.DefaultValue === 'true';
      } else if (event.value === 'int') {
        const parsedNumber = Number.parseInt(this.flag.DefaultValue as string, 10);
        this.flag.DefaultValue = isNaN(parsedNumber) ? 0 : parsedNumber;
      }
    }
  }
}
