<div class="card">
  <div class="platform-selector">
    <h2 class="row">Browse audits</h2>
    <div class="platform-newapp-container">
      <div class="ui-g">
        <div class="ui-g-4">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">Limit:</span>
            <input [(ngModel)]="limit" type="number" pInputText>
          </div>
        </div>
        <div class="ui-g-4">
          <p-checkbox label="Newest to oldest" [(ngModel)]="descending" binary="true"></p-checkbox>
        </div>
      </div>

      <div class="ui-g">
        <div class="ui-g-12">
          <span>Query:</span><span class="hint">Wrap properties with [], string values with "", dates with
            date(...).</span>
          <textarea pInputTextarea [(ngModel)]="predicate"></textarea>
        </div>
      </div>

      <div class="ui-g">
        <div class="ui-g-12">
          <button class="button load-audits" [attr.disabled]="buttonDisabled ? true : null"
            (click)="fetchAudits()">Get</button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-table [value]="audits" autoLayout="true">
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col width="50">
      <col width="50">
      <col width="50">
      <col width="50">
      <col>
      <col>
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Time</th>
      <th>Container</th>
      <th>Context</th>
      <th>UserName</th>
      <th>Payload</th>
      <th>Exception</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-audit>
    <tr [ngClass]="{ 'failed' : audit.IsSuccess === false }">
      <td>{{audit.Time}}</td>
      <td>{{audit.Container}}</td>
      <td>{{audit.Context}}</td>
      <td>{{audit.UserName}}</td>
      <td>{{audit.Payload | json}}</td>
      <td>{{audit.Exception}}</td>
    </tr>
  </ng-template>
</p-table>