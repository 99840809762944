<div class="card">
  <div class="platform-selector">
    <h2 class="row">Browse logs</h2>
    <div class="platform-newapp-container">
      <div class="ui-g">
        <div class="ui-g-4">
          <div class="ui-inputgroup">
            <span class="ui-inputgroup-addon">Limit:</span>
            <input [(ngModel)]="limit" type="number" pInputText>
          </div>
        </div>
        <div class="ui-g-4">
          <p-checkbox label="Newest to oldest" [(ngModel)]="descending" binary="true"></p-checkbox>
        </div>
        <div class="ui-g-4">
          <p-checkbox label="Easy mode" [(ngModel)]="easyMode" binary="true"></p-checkbox>
        </div>
      </div>

      <div class="ui-g" *ngIf="!easyMode">
        <div class="ui-g-12">
          <span>Query:</span><span class="hint">Wrap properties with [], string values with "", dates with
            date(...).</span>
          <textarea pInputTextarea [(ngModel)]="predicate"></textarea>
        </div>
      </div>
      
      <div class="ui-inputgroup" *ngIf="easyMode && getFiltersToAdd().length > 0">
        <span class="ui-inputgroup-addon">Filters</span>
        <p-dropdown [options]="getFiltersToAdd()" [(ngModel)]="filterToAdd" optionLabel="Label"></p-dropdown>
        <button class="button" (click)="addFilter()">Add</button>
      </div>
      
      <table *ngIf="easyMode">
        <colgroup>
          <col width="80">
          <col>
        </colgroup>
        <tbody>
          <ng-container *ngFor="let item of filters;let index = index;" >
            <tr *ngIf="item.Enabled">
              <td>{{item.Label}}</td>
  
              <td *ngIf="item.Type == 'string'"><input type="text" [(ngModel)]="item.Value" /></td>
              <td *ngIf="item.Type == 'bool'"><p-checkbox [(ngModel)]="item.Value" binary="true"></p-checkbox></td>
              <td *ngIf="item.Type == 'datetime'"><p-calendar [(ngModel)]="item.Value" [showTime]="true"></p-calendar></td>
  
              <td><button class="button remove-filter" (click)="removeFilter(index)">X</button></td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <div class="ui-g">
        <div class="ui-g-12">
          <button class="button load-logs" [attr.disabled]="buttonDisabled ? true : null"
            (click)="fetchLogs()">Get</button>
        </div>
      </div>
    </div>
  </div>
</div>
<p-table [value]="logs" [resizableColumns]="true">
  <ng-template pTemplate="colgroup">
    <colgroup>
      <col>
      <col>
      <col>
      <col>
      <col>
      <col>
      <col>
      <col>
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width:5%">Time</th>
      <th style="width:8%">Container</th>
      <th style="width:8%">Log</th>
      <th style="width:8%">Username</th>
      <th style="width:5%">Level</th>
      <th style="width:22%">Message</th>
      <th style="width:22%">Properties</th>
      <th style="width:22%">Exception</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-log>
    <tr>
      <td>{{log.Timestamp}}</td>
      <td>{{log.Container}}</td>
      <td>{{log.Log}}</td>
      <td>{{log.Username}}</td>
      <td>{{log.Level}}</td>
      <td>{{log.RenderedMessage}}</td>
      <td>{{log.Properties | json}}</td>
      <td>{{log.Exception}}</td>
    </tr>
  </ng-template>
</p-table>