import * as moment from 'moment';

export function backendDateToDate(date: string): moment.Moment {
  const milli = date.replace(/\/Date\((-?\d+)\)\//, '$1');
  return moment(parseInt(milli, 10));
}

export function humanizeDuration(date: string): string {
  return moment.duration(date).humanize(true);
}

export function toFormattedDateString(date: Date): string {
  return moment(getUTCDate(date)).format("YYYY-MM-DDTHH:mm:ss");
}

export function getUTCDate(date: Date): Date {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
}
