<div class="dialog__content">
  <div class="flag-dialog__row">
    <span>Name: </span>
    <input type="text" [value]="flag?.Name" (change)="flag.Name = $event.target.value" [disabled]="editMode" />
  </div>
  <div class="flag-dialog__row">
    <span>Is Enterprise: </span>
    <mat-slide-toggle color="primary" [checked]="flag?.IsEnterprise" (change)="flag.IsEnterprise = $event.checked"></mat-slide-toggle>
  </div>
  <div class="flag-dialog__row">
    <span>Can Be Set By Network Admin</span>
    <mat-slide-toggle color="primary" [checked]="flag?.CanBeSetByNetworkAdmin" (change)="flag.CanBeSetByNetworkAdmin = $event.checked"></mat-slide-toggle>
  </div>
  <div class="flag-dialog__row">
    <span>Data Type</span>
    <p-dropdown
      [appendTo]="'body'"
      [options]="dataTypeDropdownOptions"
      [ngModel]="flag?.DataType"
      (onChange)="onDataTypeChange($event)">
    </p-dropdown>
  </div>
  <div class="flag-dialog__row">
    <span>Default Value: </span>
    <input *ngIf="flag?.DataType === 'int'"
      type="number" [value]="flag?.DefaultValue"
      (change)="flag.DefaultValue = $event.target.value"/>
    <mat-slide-toggle *ngIf="flag?.DataType === 'bool'"
      color="primary" [checked]="flag?.DefaultValue"
      (change)="flag.DefaultValue = $event.checked">
    </mat-slide-toggle>
  </div>
</div>
