import {
  App,
  DeployFromResourceRequest,
  DeployRequestBase,
  Platform,
  ValidationResult,
  DeploymentSource,
  DeployRequest,
  DeployTarget,
  TargetLevel
  } from '../models/constants';
import { BackendService } from './backend.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeploymentService {

  app: App;
  source: DeploymentSource;
  targetLevel: TargetLevel;
  platform: Platform;
  target: DeployTarget;
  resource: File;
  fileKey: string;
  deployTime: string;
  excludedNetworkIds: string[];

  platforms: Platform[];

  error: Subject<string[]> = new Subject<string[]>();
  success: Subject<void> = new Subject<void>();

  constructor(private backend: BackendService) { }

  async init() {
    const platformsResponse = await this.backend.fetchPlatforms().toPromise();
    if (platformsResponse) {
      this.platforms = platformsResponse.Result;
    }
  }

  setPlatform(platformName: string) {
    this.platform = this.platforms.find(plt => plt.Platform === platformName);
  }

  sendDeployRequest() {
    this.error.next([]);
    const validationResult = this.validate();
    if (validationResult.valid) {
      this.error.next([]);
      const requestDataBase: DeployRequestBase = {
        AppName: this.app.AppName,
        LevelId: this.target.LevelId,
        Level: this.target.Level,
        Platform: this.platform.Platform
      };
      const requestData = this.prepareDeploymentRequest(requestDataBase);
      this.backend.deployApp(requestData, this.source).subscribe((response) => {
        if (response.Success && response.Result.State !== 'Error') {
          this.success.next();
        } else if (response.Result && response.Result.SubState) {
          this.error.next([response.Result.SubState]);
        }
      });
    } else {
      this.error.next(validationResult.errors);
    }
  }

  validate(): ValidationResult {
    const validationResult = new ValidationResult();
    if (this.target.Level !== 'Enplug' &&  !this.target.LevelId){
      validationResult.errors.push('Level id is not set');
      return validationResult;
    }
    if (!this.source) {
      validationResult.errors.push('Deployment source is not set');
      return validationResult;
    }
    if (!this.app) {
      validationResult.errors.push('App is not selected');
      return validationResult;
    }
    if (!this.platform) {
      validationResult.errors.push('The platform is not selected');
    }
    if (this.source === 'build') {
      if (this.app.AppVersion == null) {
        validationResult.errors.push('An app version needs to be set for a deployment from build');
      }
      if (this.app.BuildVersion == null) {
        validationResult.errors.push('A build version needs to be set for a deployment form build');
      }
    }
    if (this.source === 'resource') {
      if (!this.app.AppVersion) {
        validationResult.errors.push('An app version needs to be set for a deployment from resource');
      }
      if (!this.fileKey) {
        validationResult.errors.push('You need to upload a resource for a deployment from resource');
      }
    }
    return validationResult;
  }

  reset() {
    this.app = null;
    this.source = 'build';
    this.targetLevel = 'Enplug';
    this.deployTime = null;
    this.target.Name = 'All devices';
    this.target.Level = 'Enplug';
    this.target.LevelId = null;
    this.fileKey = null;
    this.resource = null;
    this.excludedNetworkIds = [];
  }

  private prepareDeploymentRequest(requestData: DeployRequestBase): DeployRequest {
    if (requestData.Level === 'Enplug' && this.excludedNetworkIds && this.excludedNetworkIds.length > 0) {
      requestData.ExcludeNetworkIds = this.excludedNetworkIds;
    }
    if (this.source === 'build') {
      requestData = Object.assign(requestData, {
        DeployTime: this.deployTime,
        AppVersion: this.app.AppVersion,
        BuildVersion: this.app.BuildVersion,
      });
    } else if (this.source === 'resource') {
      requestData = Object.assign(requestData, {
        AppVersion: this.app.AppVersion,
        FileKey: this.fileKey,
      }) as DeployFromResourceRequest;
    }
    return requestData as DeployRequest;
  }
}
