import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendResponse, FeatureFlagDescription, FEATURE_FLAGS_URL, FeatureFlagOverride } from '../models/constants';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {

  flagInEdit: FeatureFlagDescription;
  editing = false;

  constructor(private http: HttpClient) { }

  fetchFeatureFlags(): Promise<FeatureFlagDescription[]> {
    return this.http.get<BackendResponse<FeatureFlagDescription[]>>(
      `${environment.coreServerUrl}${FEATURE_FLAGS_URL}`, { withCredentials: true }).pipe(
        map(response => response.Result)
      ).toPromise();
  }

  fetchFeatureFlagsOverrides(flagName: string): Promise<FeatureFlagOverride[]> {
    return this.http.get<BackendResponse<FeatureFlagOverride[]>>(
      `${environment.coreServerUrl}${FEATURE_FLAGS_URL}/${flagName}/overrides`,
      { withCredentials: true }
    ).pipe(
      map(response => response.Result)
    ).toPromise();
  }

  postFeatureFlag(flag: FeatureFlagDescription): Promise<BackendResponse<void>> {
    return this.http.post<BackendResponse<void>>(
      `${environment.coreServerUrl}${FEATURE_FLAGS_URL}`,
      flag,
      { withCredentials: true },
    ).toPromise();
  }

  deleteFeatureFlag(flagName: string): Promise<BackendResponse<void>> {
    return this.http.delete<BackendResponse<void>>(
      `${environment.coreServerUrl}${FEATURE_FLAGS_URL}/${flagName}`,
      { withCredentials: true}
    ).toPromise()
  }

}
