  <div class="card">
    <div class="app-selector">
      <h2>Select App To Deploy</h2>
      <table mat-table [dataSource]="datasource" matSort>

        <ng-container matColumnDef="AppName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell
              *matCellDef="let deployment"
              class="table-item-name"> {{ deployment.AppName }}</td>
        </ng-container>

        <ng-container matColumnDef="AppVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> App Version </th>
          <td mat-cell *matCellDef="let deployment"> {{ deployment.AppVersion }} </td>
        </ng-container>

        <ng-container matColumnDef="BuildVersion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Build Version </th>
          <td mat-cell *matCellDef="let deployment"> {{ deployment.BuildVersion }} </td>
        </ng-container>

        <ng-container matColumnDef="State">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
          <td mat-cell *matCellDef="let deployment"> {{ deployment.State }} </td>
        </ng-container>

        <ng-container matColumnDef="deployedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Deployed On </th>
          <td mat-cell *matCellDef="let deployment"> {{ getReadableDeployDate(deployment.Created, deployment.DeployTime) }} </td>
        </ng-container>

        <ng-container matColumnDef="Platform">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Platform </th>
          <td mat-cell *matCellDef="let deployment"> {{ deployment.Platform }} </td>
        </ng-container>

        <ng-container matColumnDef="Level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Level </th>
          <td mat-cell *matCellDef="let deployment"> {{ deployment.Level }} </td>
        </ng-container>

        <ng-container matColumnDef="LevelId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> LevelId </th>
          <td class="level-id__cell" mat-cell *matCellDef="let deployment"> {{ deployment.LevelId }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let deployment; columns: columnsToDisplay"
          (click)="openDeploymentDialog(deployment)"
          class="table-row"></tr>

      </table>

  <p-dialog [(visible)]="deployDialogOpen"
   [modal]="true"
   [dismissableMask]="true"
   [header]="getDialogTitle()"
   [closable]="true"
   (onHide)="closeDialog()">
    <ep-deployment-dialog 
      [deployments]="deployments">
    </ep-deployment-dialog>
    <p-footer class="dialog-footer">
      <button class="button primary" (click)="saveDeployment()">Deploy</button>
      <button class="button primary" (click)="closeDialog()">Cancel</button>
    </p-footer>
  </p-dialog>