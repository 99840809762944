import { Component, Input } from '@angular/core';
import { WeWorkInvoiceRequest, WEWORK_DEFAULT_COST_PER_UNIT } from '../../models/wework';
import { WeWorkService } from '../../services/wework.service';

@Component({
  selector: 'ep-wework-confirmation-dialog',
  templateUrl: './wework-confirmation-dialog.component.html',
  styleUrls: ['./wework-confirmation-dialog.component.scss']
})
export class WeworkConfirmationDialogComponent {

  @Input() invoiceData: WeWorkInvoiceRequest;
  defaultPrice = WEWORK_DEFAULT_COST_PER_UNIT;

  constructor(private wework: WeWorkService) { }

  calculateTotal(): number {
    if (this.invoiceData && this.invoiceData.Invoices && this.invoiceData.Invoices.length > 0) {
      return this.invoiceData.Invoices.reduce((acc, bill) => acc + (bill.DeviceCount * WEWORK_DEFAULT_COST_PER_UNIT), 0);
    } else {
      return 0;
    }
  }

  mapToCompanyName(companyId: string): string {
    const company = this.wework.companies.find(cmp => companyId === cmp.CompanyReferenceId);
    return company ? company.Name : `Company ID: ${companyId}`;
  }

  onDateChange(date: Date) {
    if (this.invoiceData) {
      this.invoiceData.InvoiceDate = date;
    }
  }

}
