import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter } from '@angular/core';
import { WEWORK_DEFAULT_COST_PER_UNIT, WeWorkDisplayGroup, WeWorkInvoiceRequest } from '../../models/wework';
import { WeWorkService } from '../../services/wework.service';

@Component({
  selector: 'ep-wework-invoice',
  templateUrl: './wework-invoice.component.html',
  styleUrls: ['./wework-invoice.component.scss']
})
export class WeWorkInvoiceComponent {

  wework: WeWorkService;

  filterInput: string;
  locationDialogOpen = false;
  locationDialogReset$ = new EventEmitter<void>();
  locationDialogOpen$ = new EventEmitter<void>();

  locationsToSend: { [key: string]: boolean } = {};
  companiesToBill: WeWorkInvoiceRequest;
  confirmationDialogOpen = false;

  constructor(route: ActivatedRoute, wework: WeWorkService) {
    this.wework = wework;
  }

  get displayedDisplayGroups(): WeWorkDisplayGroup[] {
    return this.filterBySearchInput(this.wework.mappedDisplayGroups);
  }

  get selectedDisplayGroups(): string[] {
    return Object.keys(this.locationsToSend).filter((companyId => this.locationsToSend[companyId]));
  }

  getAggregateValue(displayGroups: WeWorkDisplayGroup[]): number {
    return displayGroups.reduce((acc, displayGroup) => {
      return acc + displayGroup.DeviceCount * WEWORK_DEFAULT_COST_PER_UNIT;
    }, 0);
  }

  getAggregateCount(displayGroups: WeWorkDisplayGroup[]): number {
    return displayGroups.reduce((acc, displayGroup) => {
      return acc + displayGroup.DeviceCount;
    }, 0);
  }

  filterBySearchInput(groups: WeWorkDisplayGroup[]): WeWorkDisplayGroup[] {
    if (!this.filterInput) {
      return groups;
    } else {
      return groups.filter(displayGroup => displayGroup.DisplayGroupName.toLowerCase().includes(this.filterInput));
    }
  }

  filterOutUnmapped(groups: WeWorkDisplayGroup[]): WeWorkDisplayGroup[] {
    return groups.filter(displayGroup => displayGroup.CompanyId !== null);
  }

  closeLocationDialog() {
    this.locationDialogOpen = false;
    this.locationDialogReset$.next();
  }

  getDialogTitle(): string {
    return this.wework.displayGroupInEdit ? this.wework.displayGroupInEdit.DisplayGroupName : 'Add Mapping';
  }

  async saveLocation() {
    await this.wework.saveMapping(this.wework.displayGroupInEdit,
      this.wework.displayGroupInEdit.CompanyId).catch(err => alert(err));
    this.closeLocationDialog();
  }

  saveButtonDisabled(): boolean {
    if (this.wework.displayGroupInEdit) {
      const originalGroup = this.wework.displayGroups.find(gr => gr.DisplayGroupId === this.wework.displayGroupInEdit.DisplayGroupId);
      if (originalGroup) {
        if (originalGroup.CompanyId !== this.wework.displayGroupInEdit.CompanyId ||
            originalGroup.DeviceCount !== this.wework.displayGroupInEdit.DeviceCount) {
            return false;
        }
      }
    }
    return true;
  }

  openLocationDialog(displaygroup?: WeWorkDisplayGroup) {
    if (displaygroup) {
      this.wework.displayGroupInEdit = Object.assign({}, displaygroup);
    } else {
      this.wework.displayGroupInEdit = null;
    }
    this.locationDialogOpen = true;
  }

  openConfirmationDialog() {
    this.confirmationDialogOpen = true;
    this.companiesToBill = this.wework.prepareInvoice(this.selectedDisplayGroups);
  }

  async sendInvoice() {
    if (this.companiesToBill) {
      await this.wework.sendInvoiceRequest(this.companiesToBill).catch(err => alert(err));
      this.companiesToBill = null;
      this.confirmationDialogOpen = false;
      this.locationsToSend = {};
    }
  }

  invoiceButtonDisabled() {
    return !(this.wework.mappedDisplayGroups && this.wework.mappedDisplayGroups.length > 0 && this.selectedDisplayGroups.length > 0);
  }

  toggleAll () {
    let valueToSet: boolean;
    const allSelected = Object.keys(this.locationsToSend)
      .map((key) => this.locationsToSend[key]) // map to booleans
      .every(selected => selected);
    if (allSelected) {
      valueToSet = false;
    } else {
      valueToSet = true;
    }
    for (const group of this.wework.mappedDisplayGroups) {
      this.locationsToSend[group.CompanyId] = valueToSet;
    }
  }

  updateDisplayGroupWithDeviceCount(group: WeWorkDisplayGroup, count: number) {
    group.DeviceCount = count;
    this.wework.updateDisplayGroup(group, group.CompanyId);
  }
}
