import {
  App,
  BackendResponse,
  ALL_DEPLOYMENTS_URL,
  EDU_STATUSES_URL,
  Platform,
  PLATFORMS_URL,
  DeployRequest,
  DEPLOY_FROM_BUILD_URL,
  DEPLOY_FROM_RESOURCE_URL,
  DeploymentSource,
  DeviceDetils as DeviceDetails,
  NETWORKS_URL,
  Network,
  ACCOUNTS_URL,
  AccountInfo,
  Venue
  } from '../models/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { backendDateToDate } from './utils';

@Injectable({ providedIn: 'root' })
export class BackendService {

  constructor(private http: HttpClient) { }

  fetchDeployments(): Observable<App[]> {
    return this.http.get(`${environment.coreServerUrl}${ALL_DEPLOYMENTS_URL}`, {
      withCredentials: true,
    }).pipe(
      map((response: BackendResponse<App[]>) => response.Result)
    );
  }

  fetchDevices(): Observable<BackendResponse<{ EduStatuses: DeviceDetails[] }>> {
    return this.http.post<BackendResponse<{ EduStatuses: DeviceDetails[] }>>(
      `${environment.monitoringUrl}${EDU_STATUSES_URL}`, {
        EduQueries: [],
        MetricQueries: [],
        NetworkId: null,
        StatusCode: null,
        StatusMessage: '',
      }, {
        withCredentials: true
      });
  }

  fetchNetworks(): Observable<BackendResponse<Network[]>> {
    return this.http.get<BackendResponse<Network[]>>(`${environment.coreServerUrl}${NETWORKS_URL}`, { withCredentials: true });
  }

  fetchAccounts(): Observable<BackendResponse<AccountInfo[]>> {
    return this.http.get<BackendResponse<AccountInfo[]>>(`${environment.coreServerUrl}${ACCOUNTS_URL}`, { withCredentials: true });
  }

  fetchPlatforms(): Observable<BackendResponse<Platform[]>> {
    return this.http.get<BackendResponse<Platform[]>>(`${environment.coreServerUrl}${PLATFORMS_URL}`, { withCredentials: true });
  }

  deployApp(deploymentRequest: DeployRequest, source: DeploymentSource): Observable<any> {
    let url = environment.coreServerUrl;
    switch (source) {
      case 'build':
        url += DEPLOY_FROM_BUILD_URL;
        break;
      case 'resource':
        url += DEPLOY_FROM_RESOURCE_URL;
        break;
    }
    return this.http.post(url, deploymentRequest, { withCredentials: true });
  }
}
