<div class="header">
  <span class="title">Location Billing Tool</span>
  <button class="button primary" (click)="openConfirmationDialog()" [disabled]="invoiceButtonDisabled()">Submit invoice</button>
</div>

<div class="card location-list">
  <div class="location-list-header">
    <span class="location-filter">
      <i class="icon ion-ios-search"></i>
      <input class="location-filter-input" placeholder="Search" [(ngModel)]="filterInput"/>
    </span>
    <span class="location-count">{{ displayedDisplayGroups.length }} Locations</span>
    <span class="aggregate-count">{{ getAggregateCount(displayedDisplayGroups) }} Units</span>
    <span class="aggregate-value">${{ getAggregateValue(displayedDisplayGroups) }} Total</span>
    <button class="button" (click)="openLocationDialog()">Update Mappings</button>
    <i  *ngIf="wework.unmappedDisplayGroups.length > 0"
        class="unmapped-alert icon ion-ios-alert"
        pTooltip="There are Display Groups not assigned to any company" tooltipPosition="top"></i>
  </div>
  <div class="location-list-table">
    <div class="location-list-table-header">
      <p-checkbox class="table-header checkbox" [disabled]="true" (click)="toggleAll()"></p-checkbox>
      <span class="table-header name">Location / Display Group</span>
      <span class="table-header units">Units</span>
      <span class="table-header cost">Cost Per Unit</span>
      <span class="table-header total">Total</span>
    </div>
    <div *ngFor="let displayGroup of displayedDisplayGroups" class="location-list-item">
        <p-checkbox class="table-item checkbox" 
          [(ngModel)]="locationsToSend[displayGroup.CompanyId]"
          binary="true"></p-checkbox>
        <span class="table-item name" (click)="openLocationDialog(displayGroup)">{{ displayGroup.DisplayGroupName }}</span>
        <span class="table-item units">
          <input type="number" [(ngModel)]="displayGroup.DeviceCount" min="0" />
        </span>
        <span class="table-item cost" (click)="openLocationDialog(displayGroup)">$25</span>
        <span class="table-item total" (click)="openLocationDialog(displayGroup)">${{ displayGroup.DeviceCount * 25}}</span>
    </div>
  </div>
</div>

<p-dialog [(visible)]="locationDialogOpen"
   [modal]="true"
   [dismissableMask]="true"
   [header]="getDialogTitle()"
   [closable]="true"
   (onHide)="closeLocationDialog()"
   (onShow)="locationDialogOpen$.next()">
    <ep-wework-location-dialog
      [dialogClosed$]="locationDialogReset$"
      [dialogOpened$]="locationDialogOpen$">
    </ep-wework-location-dialog>
    <p-footer class="dialog-footer">
      <button class="button primary" [disabled]="saveButtonDisabled()" (click)="saveLocation()">Save</button>
      <button class="button primary" (click)="closeLocationDialog()">Cancel</button>
    </p-footer>
  </p-dialog>

  <p-dialog
    [(visible)]="confirmationDialogOpen"
    [modal]="true"
    [header]="'Confirm Invoice'"
    [closable]="true">
      <ep-wework-confirmation-dialog
       [invoiceData]="companiesToBill">
      </ep-wework-confirmation-dialog>
      <p-footer class="dialog-footer">
          <button class="button primary" (click)="sendInvoice()">Send</button>
          <button class="button primary" (click)="confirmationDialogOpen = false;">Cancel</button>
      </p-footer>
  </p-dialog>
