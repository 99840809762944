import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ep-boolean-icon',
  templateUrl: './boolean-icon.component.html',
  styleUrls: ['./boolean-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BooleanIconComponent {

  @Input() value: boolean | string;

  // tslint:disable-next-line: no-empty
  constructor() { }

  isTrue(): boolean {
    return this.value === true || this.value === 'true' || this.value === 'True';
  }

}
