<div class="card">
  <div class="platform-selector">
    <h2 class="row">Execute command</h2>
    <div class="platform-newapp-container">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Instance:</span>
        <p-dropdown [options]="instances" [ngModel]="selectedInstance"
          (onChange)="instanceChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>

      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Type:</span>
        <p-dropdown [options]="types" [ngModel]="selectedType"
          (onChange)="typeChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>

      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Command:</span>
        <p-dropdown [options]="commands" [ngModel]="selectedCommand"
          (onChange)="commandChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>
      <p-checkbox label="Easy mode" [(ngModel)]="easyMode" binary="true" [disabled]="easyModeDisabled"></p-checkbox>
    </div>
  </div>

  <pre style="white-space: pre-wrap; word-wrap: break-word" *ngIf="showInput && (!easyMode || easyModeDisabled)">{{serializedParameters}}</pre>
  <textarea pInputTextarea [(ngModel)]="request" *ngIf="showInput && (!easyMode || easyModeDisabled)"></textarea>  

  <table *ngIf="easyMode && !easyModeDisabled">
    <colgroup>
      <col width="80">
      <col>
    </colgroup>
    <tbody>
      <tr *ngFor="let item of parameters;let index = index;">
        <td>{{item.Name}}</td>
        <td><input type="text" [(ngModel)]="inputs[index]" /></td>
      </tr>
    </tbody>
  </table>

  <button class="button execute-command" [attr.disabled]="buttonDisabled || !selectedCommand || !selectedInstance || !selectedType ? true : null" (click)="execute()">Execute</button>

  <pre style="white-space: pre-wrap; word-wrap: break-word">{{result}}</pre>
</div>