import { Component, OnInit, PlatformRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { Platform, App } from '../models/constants';
import { backendDateToDate } from '../services/utils';
import { DeploymentService } from '../services/deployment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'ep-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeploymentsComponent implements OnInit {

  deployDialogOpen = false;
  platforms: Platform[];
  deployments: App[];

  datasource: MatTableDataSource<App>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  columnsToDisplay = ['Platform', 'AppName', 'AppVersion', 'BuildVersion', 'State', 'deployedAt', 'Level', 'LevelId'];

  get selectedPlatform(): Platform {
    return this.deploymentService.platform;
  }

  constructor(private backendService: BackendService, private deploymentService: DeploymentService) { }

  async ngOnInit() {
    await this.deploymentService.init();
    const deployments = await this.backendService.fetchDeployments().toPromise();
    this.setDeployments(deployments);
    this.deploymentService.success.subscribe(async () => {
      this.closeDialog();
      this.fetchApps();
    });
  }

  openDeploymentDialog(deployment?: App) {
    if (deployment) {
      this.deploymentService.app = Object.assign({}, deployment);
      this.deploymentService.setPlatform(deployment.Platform);
    }
    this.deployDialogOpen = true;
  }

  saveDeployment() {
    this.deploymentService.sendDeployRequest();
  }

  closeDialog() {
    this.deployDialogOpen = false;
    this.deploymentService.reset();
  }

  fetchApps() {
    this.backendService.fetchDeployments().subscribe((depl: any[]) => {
      this.setDeployments(depl);
    });
  }

  getReadableDeployDate(created: string, deployTime: string) {
    const createdDate = backendDateToDate(created);
    return `${deployTime ? `${deployTime},` : ''}${createdDate.format('MMMM Do YYYY')}`;
  }

  getDialogTitle() {
    const target = this.deploymentService.target ? this.deploymentService.target.Name : 'All devices';
    const app = this.deploymentService.app ?  this.deploymentService.app.AppName : '';
    const platform = this.deploymentService.platform ? this.deploymentService.platform.Platform : 'Platform not selected';
    return `Deploy ${app} to ${target} (${platform})`;
  }

  private setDeployments(deployments: App[]) {
    this.deployments = deployments;
    if (!this.datasource) {
      this.datasource = new MatTableDataSource();
    }
    this.datasource.data = deployments;
    this.datasource.sort = this.sort;
  }
}
