<div class="card">
  <div class="platform-selector">
    <h2 class="row">Deploy AdServer</h2>
    <div class="platform-newapp-container">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon">Env:</span>
        <p-dropdown [options]="environments" [ngModel]="selectedEnvironment"
          (onChange)="environmentChanged($event.value)" optionLabel="label"></p-dropdown>
      </div>

      <button class="button fetch-changelog" [attr.disabled]="buttonDisabled ? true : null" (click)="fetchChanges()">Get
        changes</button>
      <button class="button deploy" [attr.disabled]="buttonDisabled ? true : null" (click)="deploy()">Deploy</button>
    </div>
  </div>

  <table>
    <colgroup>
      <col width="150">
      <col width="150">
      <col>
    </colgroup>
    <thead>
      <tr>
        <td>Instance</td>
        <td>Version</td>
        <td>TargetVersion</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let state of currentState;let index = index;">
        <td>{{state.Name}}</td>
        <td>{{state.Version}}</td>
        <td>
          <p-dropdown [options]="versions" [(ngModel)]="selectedTargetVersion[index]"
            (onChange)="versionChanged($event.value)" optionLabel="Version" filter=true placeholder=" "></p-dropdown>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="deployed && deployed.length > 0">
    Deployed tasks:
    <table>
      <colgroup>
        <col width="80">
        <col>
      </colgroup>
      <tbody>
        <tr *ngFor="let item of deployed">
          <td><a href="{{item.Link}}" target="_blank">{{item.Id}}</a></td>
          <td>
            {{item.Title}}
            <pre *ngFor="let note of item.Notes">{{note}}</pre>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="undeployed && undeployed.length > 0">
    Undeployed tasks:
    <table>
      <colgroup>
        <col width="80">
        <col>
      </colgroup>
      <tbody>
        <tr *ngFor="let item of undeployed">
          <td><a href="{{item.Link}}" target="_blank">{{item.Id}}</a></td>
          <td>
              {{item.Title}}
              <pre *ngFor="let note of item.Notes">{{note}}</pre>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>